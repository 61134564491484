<template>
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M4 0.5H2C1.46957 0.5 0.96086 0.710714 0.585787 1.08579C0.210714 1.46086 0 1.96957 0 2.5V18.5C0 19.0304 0.210714 19.5391 0.585787 19.9142C0.96086 20.2893 1.46957 20.5 2 20.5H4C4.53043 20.5 5.03914 20.2893 5.41421 19.9142C5.78929 19.5391 6 19.0304 6 18.5V2.5C6 1.96957 5.78929 1.46086 5.41421 1.08579C5.03914 0.710714 4.53043 0.5 4 0.5ZM14 0.5H12C11.4696 0.5 10.9609 0.710714 10.5858 1.08579C10.2107 1.46086 10 1.96957 10 2.5V18.5C10 19.0304 10.2107 19.5391 10.5858 19.9142C10.9609 20.2893 11.4696 20.5 12 20.5H14C14.5304 20.5 15.0391 20.2893 15.4142 19.9142C15.7893 19.5391 16 19.0304 16 18.5V2.5C16 1.96957 15.7893 1.46086 15.4142 1.08579C15.0391 0.710714 14.5304 0.5 14 0.5Z"
      fill="white"
    />
  </svg>
</template>
