<script setup lang="ts">
import { Station } from "@/types/prime";
import { mapStation } from "@/graphql/composables/mappers/station-mapper";
import { ref, nextTick } from "vue";
import { useEpgData } from "@/graphql/composables/getEpgData";
import { StationEnum } from "@/graphql/generated/client";
import { formatDateToYYYYMMDD } from "@/utilities/dateUtils";
import { toFullYearFormatedDate } from "@/utilities/timeUtils";
import { usePlayerStore } from "@/store/player";
import { useBreakpoints, breakpointsTailwind } from "@vueuse/core";

definePage({ meta: { pageName: "epg" } });

const playerStore = usePlayerStore();

let station = Station.None;

const route = useRoute();

if ("station" in route.params) {
  station = route.params.station as Station;
} else {
  throw new Error("No station found in route params");
}

const mappedStation = mapStation(station);
const epgScrollerHeight = ref(0);
const baseLayer = ref<HTMLDivElement>();
const epgOverlayOpen = ref<Boolean>(false);

const today = new Date();

const { result: shows, loading, load } = useEpgData(toFullYearFormatedDate());

const handleLoad = (station: StationEnum, date: string) => {
  load(undefined, { station, date });
};

mappedStation && handleLoad(mappedStation, formatDateToYYYYMMDD(today));

const findLiveShowIndex = () => {
  const currentTime = Math.floor(today.getTime() / 1000);
  const currentIndex = shows.value?.findIndex((show) => {
    const endTime = show.startTime + show.duration;
    const current = currentTime >= show.startTime && currentTime <= endTime;
    return current;
  });
  return currentIndex;
};

watch(shows, async (newVal) => {
  if (newVal) {
    await nextTick();
    const scroller = document.getElementsByClassName("epg")[0];
    epgScrollerHeight.value = scroller.scrollHeight;

    const liveShowIndex = findLiveShowIndex();
    const liveShowElement = document.querySelector(`[data-index="${liveShowIndex}"]`);
    if (liveShowElement) {
      liveShowElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }
});

const showOverlay = (bool: boolean) => {
  epgOverlayOpen.value = bool;
};

const loadTimeline = (newDate: Date) => {
  mappedStation && handleLoad(mappedStation, formatDateToYYYYMMDD(newDate));
};

const breakpoints = useBreakpoints(breakpointsTailwind);
const smallerThanLg = breakpoints.smaller("lg");
</script>

<template>
  <div class="-mx-4 lg:-mx-8">
    <div class="h-screen px-4 lg:px-8" v-if="loading && !shows">
      <SkeletonLoading />
    </div>
    <div v-else>
      <div class="epg h-screen snap-y snap-mandatory overflow-y-auto">
        <div class="epg-content absolute inset-0 z-10" ref="baseLayer">
          <div class="min-h-[33vh] snap-center"></div>
          <EpgShowItem
            v-for="(show, index) in shows"
            :data-index="index"
            :key="index"
            :show="show"
            :station="station!"
            class="min-h-[33vh] snap-center transition-transform duration-300"
            @emitShowOverlay="showOverlay"
          />
          <div class="min-h-[33vh] snap-center"></div>
        </div>
        <div
          class="epg-scrollbar absolute inset-y-0 left-0 right-2"
          :style="{ height: epgScrollerHeight + 'px' }"
        />
      </div>
    </div>
    <div
      class="fixed inset-x-0 flex items-center justify-center gap-4 pb-4 lg:pl-[267px]"
      :class="[
        { 'bottom-0': !playerStore.isActive },
        { 'bottom-28': playerStore.isActive && !smallerThanLg },
        { 'bottom-20': playerStore.isActive && smallerThanLg },
      ]"
    >
      <EPGDatePicker @emitLoadTimeline="loadTimeline" />
    </div>
  </div>
</template>

<style scoped>
.epg {
  perspective: 1px;
}
.epg-content {
  transform: translateZ(0);
}
.epg-scrollbar {
  background-color: rgba(12, 12, 18, 0.8);
  backdrop-filter: blur(38.5px);
  background-image: url("/images/bg-epg.svg");
  background-repeat: repeat-y;
  background-position: top right;
  min-height: 1000px;
  transform: translateZ(-1px) scale(2);
}

.epg-show-info {
  background: linear-gradient(
    180deg,
    rgba(16, 16, 16, 0.36) 0%,
    rgba(16, 16, 16, 0.96) 38.96%,
    #101010 69.97%
  );
  backdrop-filter: blur(15px);
  border-radius: 24px 24px 0px 0px;
}
</style>
