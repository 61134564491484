<script setup lang="ts">
import { useSearch } from "../../graphql/composables/search";
import { useDebounceFn, useInfiniteScroll, useStorage } from "@vueuse/core";
import { SearchMode, SearchResultItemType } from "../../types/prime";
import { MediaType } from "../../graphql/generated/client";
import { useSearchAdvertisement } from "@/graphql/composables/search-advertisement";

definePage({ meta: { hideMobileNavigationBar: true, pageName: "search" } });

const { result: adResult } = useSearchAdvertisement();

const searchPageMode = ref<SearchMode>(SearchMode.INITIAL_STATE);
const el = ref<HTMLElement | null>(null);
const searchQuery = ref("");
const page = ref(0);
const pageSize = ref(10);

const recentResult = useStorage<SearchResultItemType[]>("search-result", [], sessionStorage, {
  serializer: {
    read: (v: string) => (v ? JSON.parse(v) : null),
    write: (v: SearchResultItemType[]) => JSON.stringify(v),
  },
});
const selectedFilter = useStorage<MediaType>("filter-result", MediaType.All, sessionStorage);

const { loading, error, result, load, fetchMore } = useSearch();
const debouncedLoad = useDebounceFn(
  (searchQuery: string) =>
    load(undefined, {
      searchQuery,
      pageSize: pageSize.value,
      mediaType: selectedFilter.value,
    }),
  500,
);

const handleSearch = async () => {
  await debouncedLoad(searchQuery.value);
  searchPageMode.value = SearchMode.LIVE_RESULT;
  page.value = 0;
};

const handleFilter = async (f: MediaType) => {
  selectedFilter.value = f;
};

const finalResult = computed(() => {
  switch (searchPageMode.value) {
    case SearchMode.SAVED_RESULT:
      return recentResult.value;
    case SearchMode.LIVE_RESULT:
      return result.value?.result || [];
    default:
      return [];
  }
});

watch(result, () => {
  if (!result) return;

  recentResult.value = result.value?.result.slice(-10);
});

watch([searchQuery, selectedFilter], handleSearch);

useInfiniteScroll(el, () => {
  if (!(result.value && result.value.total > result.value.result.length)) return;

  if (loading.value) return;

  fetchMore({
    variables: { searchQuery: searchQuery.value, page: ++page.value, pageSize: pageSize.value },
    updateQuery(previousQueryResult, options) {
      if (!options.fetchMoreResult) return previousQueryResult;
      if (options.fetchMoreResult.search.query?.results)
        options.fetchMoreResult.search.query.results = [
          ...(previousQueryResult.search.query?.results || []),
          ...(options.fetchMoreResult.search.query?.results || []),
        ];
      return options.fetchMoreResult;
    },
  });
});
</script>

<template>
  <Error v-if="error" :message="error.message" />
  <div
    v-else
    ref="el"
    class="relative -mx-4 flex h-full flex-col overflow-auto px-4 lg:-mx-8 lg:px-8"
  >
    <div class="sticky top-0 z-20 mb-4 bg-prime-black lg:pt-8">
      <SearchBar
        :active-search-mode="searchPageMode !== SearchMode.INITIAL_STATE"
        @reset-recent-result-mode="searchPageMode = SearchMode.INITIAL_STATE"
      />
      <SearchInput
        @set-recent-result-mode="searchPageMode = SearchMode.SAVED_RESULT"
        v-model="searchQuery"
      />
      <template v-if="searchPageMode === SearchMode.INITIAL_STATE">
        <StationGuide class="mt-4 lg:mt-8" />
        <div v-if="adResult" class="flex justify-center">
          <GoogleAdManager class="mt-4" :slot-id="adResult" />
        </div>
      </template>
      <FilterBar
        class="mt-4 lg:mt-8"
        v-if="searchPageMode === SearchMode.LIVE_RESULT"
        :filter="selectedFilter"
        :on-filter="handleFilter"
      />
    </div>

    <SearchResult
      v-if="searchPageMode !== SearchMode.INITIAL_STATE"
      :results="finalResult"
      :is-history-result="searchPageMode === SearchMode.SAVED_RESULT"
    />
    <div v-if="loading" class="absolute inset-0 flex items-center justify-center bg-prime-black">
      <IconSpinner class="animate-spin" />
    </div>
  </div>
</template>
