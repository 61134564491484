<!-- 
    this is not the final version of the button component :) 
    for now it's just used for the error screen and we will have to figure out how to use it in the future
 -->

<script setup lang="ts">
import { IconName } from "./icons/type";

export type ButtonSize = "small" | "large" | "auto";

withDefaults(
  defineProps<{
    size?: ButtonSize;
    icon?: IconName;
    loading?: boolean;
    theme?: "primary" | "secondary" | "tertiary";
  }>(),
  { size: "auto", theme: "primary", icon: undefined, loading: false },
);

const IconComponent = (icon: IconName) => {
  return defineAsyncComponent(() => import(`@/components/atoms/icons/Icon${icon}.vue`));
};
</script>

<template>
  <button
    :class="[
      'w-max rounded-[7px] font-inter transition-colors focus:outline-1 focus:outline-offset-1 disabled:opacity-50',
      {
        'bg-neutral-interaction font-bold hover:bg-neutral-300': theme === 'primary',
        'bg-neutral-125 font-medium hover:bg-neutral-300': theme === 'secondary',
        ' bg-transparent font-medium': theme === 'tertiary',
      },
      {
        'px-4 py-1.5 text-sm': size === 'small',
        'px-5 py-3 text-base': size === 'large',
        'px-4 py-1.5 text-sm md:px-4 md:py-1.5 md:text-sm': size === 'auto',
      },
    ]"
    :disabled="loading"
  >
    <span
      class="flex flex-row items-center justify-center"
      :class="[
        {
          'gap-2': size === 'small',
          'gap-3': size === 'large',
          'gap-2 md:gap-3': size === 'auto',
        },
      ]"
    >
      <template v-if="icon">
        <IconSpinner
          :class="[
            {
              'h-3.5 w-3.5': size === 'small',
              'h-4 w-4': size === 'large',
              'h-3.5 w-3.5 md:h-4 md:w-4': size === 'auto',
            },
          ]"
          class="animate-spin"
          v-if="loading"
        />
        <component
          v-else
          :class="[
            {
              'h-3.5 w-3.5': size === 'small',
              'h-4 w-4': size === 'large',
              'h-3.5 w-3.5 md:h-4 md:w-4': size === 'auto',
            },
          ]"
          :is="IconComponent(icon)"
        />
      </template>
      <slot />
    </span>
  </button>
</template>
