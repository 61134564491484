<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M4.31055 12.489C4.31055 10.116 5.48975 7.87474 7.27686 6.54174C7.66504 6.21947 8.14111 6.28539 8.37549 6.6223C8.63184 6.98119 8.52197 7.40599 8.17773 7.69896C6.73486 8.80492 5.80469 10.5261 5.79736 12.489C5.79736 15.8581 8.50732 18.5681 11.8765 18.5681C15.2456 18.5681 17.9482 15.8581 17.9482 12.489C17.9482 9.63988 16.0146 7.26683 13.3779 6.61498V7.60375C13.3779 8.16771 12.9678 8.3142 12.5137 7.99193L10.0601 6.29271C9.6792 6.02904 9.68652 5.61888 10.0601 5.36254L12.521 3.66332C12.9751 3.34838 13.3779 3.49486 13.3779 4.0515V5.08422C16.8203 5.78734 19.4424 8.86351 19.4424 12.489C19.4424 16.6345 16.0146 20.0549 11.8765 20.0549C7.73828 20.0549 4.31055 16.6345 4.31055 12.489ZM9.95752 15.4553C9.61328 15.4553 9.40088 15.2136 9.40088 14.8694V10.8923H9.38623L8.71973 11.3904C8.63184 11.4563 8.55859 11.4929 8.44873 11.4929C8.22168 11.4929 8.06055 11.3171 8.06055 11.0901C8.06055 10.8996 8.15576 10.7678 8.30225 10.6579L9.07129 10.0867C9.32764 9.90355 9.55469 9.76439 9.86963 9.76439C10.2651 9.76439 10.5142 9.99144 10.5142 10.387V14.8694C10.5142 15.2136 10.3018 15.4553 9.95752 15.4553ZM13.561 15.5066C12.8872 15.5066 12.1328 15.133 11.9204 14.5032C11.8545 14.3933 11.8105 14.2688 11.8105 14.1443C11.8105 13.866 11.9863 13.6902 12.2646 13.6902C12.4697 13.6902 12.6309 13.8 12.7407 14.0198C12.8726 14.364 13.1436 14.6277 13.5464 14.6277C14.0737 14.6277 14.3594 14.1296 14.3594 13.5437C14.3594 13.009 14.0957 12.5036 13.5537 12.5036C13.2827 12.5036 13.0557 12.6501 12.8872 12.8625C12.7407 13.0456 12.6235 13.1262 12.3892 13.1262C12.0449 13.1262 11.8618 12.8625 11.8838 12.5256L12.0303 10.4602C12.0522 10.0574 12.2354 9.84496 12.6309 9.84496H14.7402C15.0039 9.84496 15.1724 10.0207 15.1724 10.2917C15.1724 10.5481 15.0039 10.7312 14.7402 10.7312H12.9385L12.8213 12.3132H12.8506C13.041 11.9324 13.4438 11.7126 13.9199 11.7126C14.8794 11.7126 15.4141 12.5476 15.4141 13.5217C15.4141 14.6643 14.7036 15.5066 13.561 15.5066Z"
      fill="white"
    />
  </svg>
</template>
