import { StationEnum } from "@/graphql/generated/client";

// values might change later
export enum Station {
  SevenZeroTwo = "702",
  NineFourSeven = "947",
  Capetalk = "capetalk",
  KFM = "kfm",
  None = "none",
}

export enum DetailPageType {
  RADIODETAIL = "RadioDetailPublishedContent",
  AUDIODETAIL = "AudioTeaserDetailPublishedContent",
  VIDEODETAIL = "VideoTeaserDetailPublishedContent",
  PODCASTDETAIL = "PodcastPublishedContent",
  AUDIOPODCASTDETAIL = "AudioPodcastPublishedContent",
  VIDEOPODCASTDETAIL = "VideoPodcastPublishedContent",
  NEWSDETAIL = "NewsTeaserDetailPublishedContent",
  STATIC = "StaticPagePublishedContent",
  COMPETITIONDETAIL = "CompetitionPublishedContent",
}

export enum ContentType {
  VIDEO_DETAIL,
  AUDIO_DETAIL,
  VIDEO_PODCAST,
  AUDIO_PODCAST,
}
export enum MediaContentType {
  AUDIO = "audio",
  VIDEO = "video",
}

export type TeaserType = NewsTeaser | MediaTeaser | CompetitionTeaser | ShowcaseTeaser | ADTeaser;

export type Reel<T extends TeaserType> = {
  title: string;
  teasers: T[];
};

export type ImageVariants = {
  lg: string;
  md: string;
  sm: string;
  imageDescription?: string;
  blurHashString?: string;
};

export type Teaser = {
  id: string;
  title: string;
  subTitle?: string;
  imageUrl: ImageVariants;
  station?: Station;
  minimal?: boolean;
};

export type HeroTeaser = Omit<Teaser, "station"> & {
  backgroundColor: string;
  detailType: DetailPageType;
  url: string;
};

export type LivestreamTeaser = Teaser & {
  progress: number;
  type?: MediaContentType;
  mediaId: string;
  startTimeInSec: number;
  durationInSec: number;
};

export type NewsTeaser = Teaser & {
  author?: string;
  type?: "video" | null;
  overline?: string;
  progress?: number; // in percentage
  duration?: number; // in minutes
  isEwnArticle?: boolean;
  url: string;
  badges?: string[];
  publishedDate?: string;
};

export type MediaTeaser = Omit<Teaser, "station"> & {
  type: MediaContentType;
  badges?: string[];
  durationInSec?: number;
  timestamp?: number;
  station?: Station;
  currentTimeInSec?: number;
  mediaId?: string;
  url: string;
};

export type ADTeaser = {
  advertisementId: string;
};

export type CompetitionTeaser = Pick<Teaser, "imageUrl"> & {
  buttonText: string;
  url: string;
};

export type ShowcaseTeaser = Pick<Teaser, "id"> & {
  imageUrl: string;
  badge: string | null | undefined;
  url: string;
};

export type Podcast = {
  coverImage: string;
  overline: string;
  headline: string;
  author?: string;
  text?: string;
  url: string;
  publishedDate?: string;
};

export type DetailPage = {
  headerImage: ImageVariants;
  coverImage: ImageVariants;
  headline: string;
  overline?: string;
  subline?: string;
  isBookmarked?: boolean;
  url?: string;
  content?: string;
  id: string;
  type: DetailPageType;
  mediaId?: string;
  contentType?: string;
  station?: Station;
};

export type RadioShowDetailPage = DetailPage & {
  reels?: (LivestreamTeaser[] | MediaTeaser[])[];
  id: string;
};

export type PodcastDetailPage = DetailPage;

export type AudioDetailPage = DetailPage & {
  relatedPodcast?: {
    url?: string;
    title?: string;
  };
};

export type ContentDetailPage = DetailPage & {
  advertisement?: string;
};

export enum NewsTeaserContentType {
  image = "imageSection",
  quote = "quoteSection",
  richText = "richContent",
  brightcove = "brightcoveContent",
  embed = "embedContent",
}

export type NewsBrightcoveContent = {
  type: NewsTeaserContentType.brightcove;
  title: string;
  detail: {
    mediaId: string;
    duration: number;
    headline: string;
    imageUrl: InlinePlayerImage;
    type: MediaContentType;
    podcastHeadline?: string;
  } | null;
};
export type NewsQuoteContent = {
  type: NewsTeaserContentType.quote;
  title: string;
  text: string;
};
export type NewsImageContent = {
  title: string;
  type: NewsTeaserContentType.image;
  url: string;
  description?: string;
};
export type NewsRichTextContent = {
  type: NewsTeaserContentType.richText;
  markup: string;
};

export type NewsEmbedContent = {
  type: NewsTeaserContentType.embed;
  content: string;
  title: string;
};

export type NewsDetailPage = DetailPage & {
  teasers: (
    | NewsBrightcoveContent
    | NewsQuoteContent
    | NewsImageContent
    | NewsRichTextContent
    | NewsEmbedContent
  )[];
  target?: [];
};

export type CompetitionDetailPage = DetailPage & {
  teasers: (
    | NewsBrightcoveContent
    | NewsQuoteContent
    | NewsImageContent
    | NewsRichTextContent
    | NewsEmbedContent
  )[];
};

export type Player = {
  headline: string;
  station: Station;
  imageUrl: ImageVariants;
  mediaId: string;
  media: {
    duration: number;
    startTime: number;
  };
};

export type EpgShow = {
  title: string;
  station?: Station;
  imageUrl: ImageVariants;
  startTime: number;
  duration: number;
  isLive?: boolean;
  detail: {
    url: string;
    type: DetailPageType;
  };
};

export enum TeaserContentType {
  COMPETITION = "CompetitionReelPublishedContent",
  MEDIA = "MediaReelPublishedContent",
  NEWS = "NewsReelPublishedContent",
  SHOWCASE = "ShowcaseReelPublishedContent",
  AD = "AdReelPublishedContent",
}

export type HomeExplore = {
  heroReel: HeroTeaser[];
  liveStreamTeaserMediaIds: string[];
  reelList: (
    | { type: TeaserContentType.MEDIA; reel: Reel<MediaTeaser> }
    | { type: TeaserContentType.COMPETITION; reel: Reel<CompetitionTeaser> }
    | { type: TeaserContentType.SHOWCASE; reel: Reel<ShowcaseTeaser> }
    | { type: TeaserContentType.NEWS; reel: Reel<NewsTeaser> }
    | { type: TeaserContentType.AD; reel: Reel<ADTeaser> }
  )[];
};

export type SearchResultType = {
  total: number;
  result: SearchResultItemType[];
};

export type SearchResultItemType = {
  pageId: string;
  headline: string;
  overline: string;
  subline: string;
  imageUrl: string;
  type: DetailPageType;
  url: string;
};

export type MyContentResultItemType = {
  pageId?: any;
  headline?: string;
  overline?: string;
  imageUrl?: string;
  type?: any;
  url?: string;
  publishDate?: string;
  mediaId?: string;
  station?: Station;
  duration?: number;
};

export enum SearchMode {
  INITIAL_STATE,
  SAVED_RESULT,
  LIVE_RESULT,
}

export type MyContentResultType = {
  total: number;
  result: MyContentResultItemType[];
};

export enum MyContentType {
  ALL,
  FOLLOWING,
  BOOKMARK,
}

export type PlayHistoryItem = {
  timestamp: string;
  mediaId: string;
  elapsedSec: number;
};

export type StationInfo = {
  stationName: string;
  stationKey: string;
  mediaId: string;
  id: string;
};

export type CurrentShow = {
  headline: string;
  station: StationEnum;
  startTimeInSec: number;
  endTimeInSec: number;
  durationInSec: number;
  backgroundColor?: string;
  backgroundImageUrl: ImageVariants;
  imageUrl: ImageVariants;
  fallbackImageUrl?: string | null;
};

export enum SocialNetwork {
  facebook = "facebook",
  twitter = "twitter",
  whatsapp = "whatsapp",
}

export type PageName =
  | "explore"
  | "microsite"
  | "detail_page"
  | "search"
  | "epg"
  | "station_selection"
  | "station_detail"
  | "my_content"
  | "my_content_bookmarks"
  | "settings";

export const episodeLimitInDetailPages = 5;

export type InlinePlayerImage = {
  audioLg?: string;
  audioMd?: string;
  audioSm?: string;
  videoLg?: string;
  videoMd?: string;
  videoSm?: string;
};

export type InlinePlayerData = {
  image: InlinePlayerImage;
  title?: string;
  mediaType: string | MediaContentType;
  mediaId: string;
  header?: string;
  duration?: number;
};
