<template>
  <svg
    aria-hidden="true"
    class="fill-current"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 6.5C13.3478 6.5 10.8043 7.55357 8.92893 9.42893C7.05357 11.3043 6 13.8478 6 16.5C6 19.1522 7.05357 21.6957 8.92893 23.5711C10.8043 25.4464 13.3478 26.5 16 26.5C18.6522 26.5 21.1957 25.4464 23.0711 23.5711C24.9464 21.6957 26 19.1522 26 16.5C26 13.8478 24.9464 11.3043 23.0711 9.42893C21.1957 7.55357 18.6522 6.5 16 6.5ZM2 16.5C2 8.768 8.268 2.5 16 2.5C23.732 2.5 30 8.768 30 16.5C30 24.232 23.732 30.5 16 30.5C8.268 30.5 2 24.232 2 16.5Z"
      fill="#101010"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0003 6.50002C13.4221 6.49422 10.9425 7.49001 9.08434 9.27735C8.69937 9.63329 8.19003 9.82383 7.66596 9.80795C7.1419 9.79206 6.64504 9.57102 6.28234 9.19241C5.91964 8.81381 5.72011 8.30792 5.72672 7.78366C5.73333 7.2594 5.94554 6.75869 6.31767 6.38935C8.92023 3.88863 12.3911 2.49446 16.0003 2.50002C16.5308 2.50002 17.0395 2.71073 17.4145 3.0858C17.7896 3.46088 18.0003 3.96958 18.0003 4.50002C18.0003 5.03045 17.7896 5.53916 17.4145 5.91423C17.0395 6.2893 16.5308 6.50002 16.0003 6.50002Z"
    />
  </svg>
</template>
