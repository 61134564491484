import { PlayerType } from "./../store/player/type";
import playerLoader from "@brightcove/player-loader";
import { usePlayerStore } from "../store/player";
import { Station } from "../types/prime";
import { useErrorStore } from "@/store/error";
import { nowInSec } from "@/utilities/timeUtils";
import { useUserStore } from "@/store/user";

type LoadError = {
  error_code: "ACCESS_DENIED";
  error_subcode: "CLIENT_GEO";
  message: string;
};

let player: any = null;

const getMedia = (mediaId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    player.catalog.getVideo(mediaId, function (error: any, media: any) {
      if (error) reject(error);
      else {
        resolve(player.catalog.load(media));
      }
    });
  });
};

export const initAudioPlayer = async (
  mediaId: string,
  startTime: number = 0,
  isLive: boolean = false,
) => {
  const playerStore = usePlayerStore();
  const userStore = useUserStore();
  playerStore.reset();

  const htmlNode = document.querySelector("#brightcovePlayer");
  htmlNode?.classList.add("sr-only");

  try {
    const success = await playerLoader({
      refNode: htmlNode,
      accountId: import.meta.env.VITE_APP_BRIGHTCOVE_ACCOUNT_ID,
      playerId: import.meta.env.VITE_APP_BRIGHTCOVE_PLAYER_ID,
    });

    playerStore.currentInitiated = PlayerType.AUDIO;
    playerStore.media.startTime = startTime;

    // Get a reference to the BC Player
    player = success.ref;

    if (!player) return;

    player.ready(function () {
      player.bcAnalytics.client.user(userStore.userId);
      player.playsinline(true);

      player.on("loadedmetadata", () => {
        playerStore.media.id = mediaId;
        playerStore.isLive = isLive;

        const duration = player.duration();
        isFinite(duration) && (playerStore.media.duration = duration);
      });
      player.on("loadstart", () => {
        playerStore.isLoading = true;
      });
      player.on("loadeddata", () => {
        playerStore.isLoading = false;
      });
      player.on("timeupdate", () => {
        playerStore.media.currentTime = !!!playerStore.media.startTime
          ? player.currentTime()
          : nowInSec() - playerStore.media.startTime;
      });
      player.on("play", () => {
        playerStore.isPlaying = true;
      });
      player.on("pause", () => {
        playerStore.isPlaying = false;
      });
      player.on("error", (e: any) => {
        handlePlayError(e);
      });
    });
  } catch (error) {
    playerStore.isPlaying = false;
    playerStore.isLoading = false;

    const errorStore = useErrorStore();
    errorStore.show("Error while loading the media.");
  }
};

export const initVideoPlayer = async (mediaId: string, isLive: boolean = false) => {
  const playerStore = usePlayerStore();
  const userStore = useUserStore();
  playerStore.reset();

  const htmlNode = document.querySelector("#brightcovePlayer");

  try {
    const success = await playerLoader({
      refNode: htmlNode,
      accountId: import.meta.env.VITE_APP_BRIGHTCOVE_ACCOUNT_ID,
      playerId: import.meta.env.VITE_APP_BRIGHTCOVE_PLAYER_ID,
    });

    playerStore.currentInitiated = PlayerType.VIDEO;

    // Get a reference to the BC Player
    player = success.ref;

    if (!player) return;

    player.ready(function () {
      player.bcAnalytics.client.user(userStore.userId);
      player.playsinline(false);

      player.on("loadedmetadata", () => {
        htmlNode?.classList.remove("sr-only");
        playerStore.media.id = mediaId;
        playerStore.isLive = isLive;

        const duration = player.duration();
        isFinite(duration) && (playerStore.media.duration = duration);
      });
      player.on("play", () => {
        playerStore.isPlaying = true;
      });
      player.on("pause", () => {
        playerStore.isPlaying = false;
      });

      addCloseButton();
    });
  } catch (error) {
    playerStore.isPlaying = false;
    playerStore.isLoading = false;

    const errorStore = useErrorStore();
    errorStore.show("Error while loading the media.");
  }
};

export const load = async (mediaId: string) => {
  try {
    if (!player) return false;
    await getMedia(mediaId);
    return true;
  } catch (error) {
    handleLoadError(error);
    return false;
  }
};

export const play = () => {
  if (!player) return;

  player.muted(false);
  player.play();
};

export const pause = () => {
  player?.pause();
};

export const reset = () => {
  player?.currentTime(0);
};

export const rewind = (sec: number) => {
  player?.currentTime(player.currentTime() - sec);
};

export const advance = (sec: number) => {
  player?.currentTime(player.currentTime() + sec);
};

// progress in percent
export const seek = (progress: number) => {
  if (!player) return;

  const playerStore = usePlayerStore();

  const newCurrentTime = (progress * player.duration()) / 100;
  playerStore.media.currentTime = newCurrentTime;
  player.currentTime(newCurrentTime);
};

export const cast = () => {
  const castButton =
    document.querySelector(".vjs-airplay-button") ||
    document.querySelector(".vjs-chromecast-button");

  if (castButton) {
    (castButton as HTMLButtonElement).click();
  }
};

export const isCastingAvailable = !!(
  document.querySelector(".vjs-airplay-button") || document.querySelector(".vjs-chromecast-button")
);

export const loadAndPlay = async (
  mediaId: string,
  type: PlayerType,
  title: string = "no title",
  station: Station = Station.None,
  imageUrl?: string,
  currentTime?: number, // for episodes
  startTime?: number, // used for live stream
  duration?: number, // used for live stream
  isLive: boolean = false,
) => {
  player?.dispose();

  const playerStore = usePlayerStore();

  if (type === PlayerType.AUDIO) {
    await initAudioPlayer(mediaId, startTime, isLive);
  } else {
    await initVideoPlayer(mediaId, isLive);
  }

  const isLoaded = await load(mediaId);

  if (!isLoaded) {
    return;
  }

  if (title && station && imageUrl && type === PlayerType.AUDIO)
    playerStore.setAudioInfo(title, station, imageUrl, duration);

  if (mediaId === playerStore.media?.id && !startTime && !duration) {
    // not live stream
    player?.currentTime(playerStore.media?.currentTime);
  }

  if (currentTime) {
    // playback history
    player?.currentTime(currentTime);
  }

  play();
};

const handleLoadError = (e: unknown) => {
  const errorStore = useErrorStore();

  if (e instanceof XMLHttpRequest)
    if (e.status == 403) {
      const parsedResponse = JSON.parse(e.response);
      if (parsedResponse instanceof Array) {
        const loadError = parsedResponse[0] as LoadError;

        if (loadError.error_code === "ACCESS_DENIED" && loadError.error_subcode === "CLIENT_GEO") {
          errorStore.show("This content is not supported in your region");
        }
      }
    }
};

const handlePlayError = (e: any) => {
  const errorStore = useErrorStore();
  const playerStore = usePlayerStore();

  if (e.type === "error") {
    playerStore.reset();
    errorStore.show("Error while playing the media, please try again later.");
  }
};

const addCloseButton = () => {
  const htmlNode = document.querySelector("#brightcovePlayer");
  const closeVideoButton = document.createElement("button");
  closeVideoButton.className += " absolute top-2 right-2 text-white z-10 w-4 h-4 ";
  closeVideoButton.innerHTML = `
  <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="stroke-current w-full h-auto"
>
  <path
    d="M5 5L19 19M5 19L19 5"
    stroke-width="2.8"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;
  closeVideoButton.addEventListener("click", () => {
    player.pause();
    htmlNode?.classList.add("sr-only");
  });
  htmlNode?.appendChild(closeVideoButton);
};
