<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M8.89232 13.6416L14.4736 8.06034L13.3652 6.952L8.89232 11.4249L6.63607 9.16867L5.52773 10.277L8.89232 13.6416ZM10.0007 17.9166C8.90551 17.9166 7.87635 17.7086 6.91315 17.2928C5.94996 16.8774 5.11211 16.3135 4.39961 15.601C3.68711 14.8885 3.12318 14.0506 2.70782 13.0874C2.29193 12.1242 2.08398 11.0951 2.08398 9.99992C2.08398 8.90478 2.29193 7.87561 2.70782 6.91242C3.12318 5.94922 3.68711 5.11138 4.39961 4.39888C5.11211 3.68638 5.94996 3.12218 6.91315 2.70629C7.87635 2.29093 8.90551 2.08325 10.0007 2.08325C11.0958 2.08325 12.125 2.29093 13.0882 2.70629C14.0513 3.12218 14.8892 3.68638 15.6017 4.39888C16.3142 5.11138 16.8781 5.94922 17.2935 6.91242C17.7094 7.87561 17.9173 8.90478 17.9173 9.99992C17.9173 11.0951 17.7094 12.1242 17.2935 13.0874C16.8781 14.0506 16.3142 14.8885 15.6017 15.601C14.8892 16.3135 14.0513 16.8774 13.0882 17.2928C12.125 17.7086 11.0958 17.9166 10.0007 17.9166Z"
    />
  </svg>
</template>
