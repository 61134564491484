import _definePage_default_0 from '/home/vsts/work/1/s/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/vsts/work/1/s/src/pages/[...path]/index.vue?definePage&vue'
import _definePage_default_3 from '/home/vsts/work/1/s/src/pages/competitions/[name].vue?definePage&vue'
import _definePage_default_4 from '/home/vsts/work/1/s/src/pages/my-content/index.vue?definePage&vue'
import _definePage_default_5 from '/home/vsts/work/1/s/src/pages/my-content/[type].vue?definePage&vue'
import _definePage_default_6 from '/home/vsts/work/1/s/src/pages/search/index.vue?definePage&vue'
import _definePage_default_7 from '/home/vsts/work/1/s/src/pages/settings/index.vue?definePage&vue'
import _definePage_default_8 from '/home/vsts/work/1/s/src/pages/sites/[name].vue?definePage&vue'
import _definePage_default_9 from '/home/vsts/work/1/s/src/pages/station/[id].vue?definePage&vue'
import _definePage_default_10 from '/home/vsts/work/1/s/src/pages/station/[station]/guide.vue?definePage&vue'
import _definePage_default_11 from '/home/vsts/work/1/s/src/pages/stations/index.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/home/vsts/work/1/s/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:path(.*)',
    /* internal name: '/[...path]' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/[...path]/',
        component: () => import('/home/vsts/work/1/s/src/pages/[...path]/index.vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  {
    path: '/auth',
    /* internal name: '/auth' */
    /* no component */
    children: [
      {
        path: 'callback',
        name: '/auth/callback',
        component: () => import('/home/vsts/work/1/s/src/pages/auth/callback.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/competitions',
    /* internal name: '/competitions' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':name',
        name: '/competitions/[name]',
        component: () => import('/home/vsts/work/1/s/src/pages/competitions/[name].vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  {
    path: '/error',
    /* internal name: '/error' */
    /* no component */
    children: [
      {
        path: 'connection-error',
        name: '/error/connection-error',
        component: () => import('/home/vsts/work/1/s/src/pages/error/connection-error.vue'),
        /* no children */
      },
      {
        path: 'empty-error',
        name: '/error/empty-error',
        component: () => import('/home/vsts/work/1/s/src/pages/error/empty-error.vue'),
        /* no children */
      },
      {
        path: 'something-went-wrong',
        name: '/error/something-went-wrong',
        component: () => import('/home/vsts/work/1/s/src/pages/error/something-went-wrong.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/my-content',
    /* internal name: '/my-content' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/my-content/',
        component: () => import('/home/vsts/work/1/s/src/pages/my-content/index.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: ':type',
        name: '/my-content/[type]',
        component: () => import('/home/vsts/work/1/s/src/pages/my-content/[type].vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/preview',
    /* internal name: '/preview' */
    /* no component */
    children: [
      {
        path: ':path(.*)',
        name: '/preview/[...path]',
        component: () => import('/home/vsts/work/1/s/src/pages/preview/[...path].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/search',
    /* internal name: '/search' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/search/',
        component: () => import('/home/vsts/work/1/s/src/pages/search/index.vue'),
        /* no children */
      },
  _definePage_default_6
  )
    ],
  },
  {
    path: '/settings',
    /* internal name: '/settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/settings/',
        component: () => import('/home/vsts/work/1/s/src/pages/settings/index.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/sites',
    /* internal name: '/sites' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':name',
        name: '/sites/[name]',
        component: () => import('/home/vsts/work/1/s/src/pages/sites/[name].vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  {
    path: '/station',
    /* internal name: '/station' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: '/station/[id]',
        component: () => import('/home/vsts/work/1/s/src/pages/station/[id].vue'),
        /* no children */
      },
  _definePage_default_9
  ),
      {
        path: ':station',
        /* internal name: '/station/[station]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'guide',
            name: '/station/[station]/guide',
            component: () => import('/home/vsts/work/1/s/src/pages/station/[station]/guide.vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      }
    ],
  },
  {
    path: '/stations',
    /* internal name: '/stations' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/stations/',
        component: () => import('/home/vsts/work/1/s/src/pages/stations/index.vue'),
        /* no children */
      },
  _definePage_default_11
  )
    ],
  },
  {
    path: '/ui',
    name: '/ui',
    component: () => import('/home/vsts/work/1/s/src/pages/ui.vue'),
    /* no children */
  },
  {
    path: '/user',
    /* internal name: '/user' */
    /* no component */
    children: [
      {
        path: 'login',
        name: '/user/login',
        component: () => import('/home/vsts/work/1/s/src/pages/user/login.vue'),
        /* no children */
      },
      {
        path: 'logout',
        name: '/user/logout',
        component: () => import('/home/vsts/work/1/s/src/pages/user/logout.vue'),
        /* no children */
      }
    ],
  }
]
