<template>
  <div class="max-w-full overflow-hidden">
    <div class="text-content whitespace-nowrap"><slot /></div>
  </div>
</template>

<style scoped>
.text-content {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: scroll-animation 15s linear infinite;
  -webkit-animation: scroll-animation 15s linear infinite;
  animation: scroll-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes scroll-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes scroll-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
</style>
