<script setup lang="ts">
import { useBreakpoints } from "@vueuse/core";

const isMenuOpen = ref(false);

const breakpoints = useBreakpoints({ desktop: 1200 });
const largerThanDesktop = breakpoints.greater("desktop");

const route = useRoute();

watch(
  () => route.fullPath,
  () => {
    isMenuOpen.value = false;
  },
);

const hideMobileNavBar = computed(
  () => route.meta.hideMobileNavigationBar || route.query.hideMobileNavBar,
);
</script>

<template>
  <main id="main-container" class="relative flex h-screen w-full flex-row overflow-hidden">
    <nav
      class="fixed inset-y-0 z-50 flex flex-col gap-6 overflow-auto bg-neutral-100 text-xl font-medium transition-opacity duration-500 delay-200 ease-in-out lg:relative"
      :class="
        largerThanDesktop || isMenuOpen
          ? ' inset-x-0 opacity-100 lg:max-w-[300px]'
          : 'w-0 overflow-hidden p-0 opacity-0'
      "
    >
      <div class="sticky top-10 m-4 mx-10 flex h-full flex-col lg:m-10">
        <div class="grid grid-cols-3 lg:block">
          <div></div>
          <router-link to="/" aria-label="Home" class="flex items-center">
            <PrimeLogo class="h-3 w-auto lg:h-4" />
          </router-link>
          <IconClose
            class="h-6 cursor-pointer justify-self-end lg:hidden"
            @click="isMenuOpen = !isMenuOpen"
          />
        </div>
        <SideMenu />
      </div>
    </nav>
    <div class="w-full min-w-0 flex-1 px-4 lg:px-8">
      <MobileNavigationBar v-if="!hideMobileNavBar" @toggle-menu="isMenuOpen = !isMenuOpen" />
      <div
        class="pointer-events-none fixed left-4 top-4 aspect-square w-6 rounded-full bg-neutral-100 transition-transform duration-500"
        :class="isMenuOpen ? 'z-20 scale-[71] opacity-100' : 'scale-0 opacity-0'"
      ></div>
      <slot />
      <Miniplayer />
    </div>
  </main>
</template>

<style scoped></style>
