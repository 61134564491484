import { User } from "oidc-client-ts";
import { defineStore } from "pinia";
import { getUser, removeUser, slientSignin } from "@/services/auth";

type UserStore = {
  user: User | null;
};

export const useUserStore = defineStore({
  id: "user",
  state: (): UserStore => ({ user: null }),
  getters: {
    isLoggedIn: (state) => !!state.user?.access_token,
    userId: (state) => state.user?.profile.sub,
  },
  actions: {
    async loadUser() {
      try {
        const localUser = await getUser();

        this.user = localUser ? await slientSignin() : localUser;

        return this.user;
      } catch (err) {
        return null;
      }
    },
    async removeUser() {
      await removeUser();
      this.user = null;
    },
  },
});
