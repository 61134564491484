<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M4.31055 12.5376C4.31055 8.90481 6.93994 5.82864 10.375 5.13284V4.06351C10.375 3.49222 10.7925 3.34573 11.2466 3.668L13.7002 5.41849C14.0664 5.68216 14.0664 6.09231 13.7002 6.34866L11.2393 8.09915C10.7852 8.42142 10.375 8.26028 10.375 7.70364V6.6636C7.73828 7.31546 5.80469 9.68851 5.80469 12.5376C5.80469 15.9068 8.50732 18.6167 11.8765 18.6167C15.2456 18.6167 17.9556 15.9068 17.9556 12.5376C17.9482 10.5747 17.0181 8.85354 15.5752 7.74759C15.231 7.45462 15.1211 7.02981 15.3774 6.67093C15.6118 6.33401 16.0879 6.2681 16.4761 6.59036C18.2632 7.92337 19.4424 10.1646 19.4424 12.5376C19.4424 16.6831 16.0146 20.1035 11.8765 20.1035C7.73828 20.1035 4.31055 16.6831 4.31055 12.5376ZM9.92822 15.5039C9.58398 15.5039 9.37158 15.2622 9.37158 14.918V10.9409H9.34961L8.69043 11.439C8.60254 11.5049 8.52197 11.5415 8.41211 11.5415C8.18506 11.5415 8.03125 11.3658 8.03125 11.1387C8.03125 10.9483 8.11914 10.8164 8.27295 10.7066L9.04199 10.1353C9.29102 9.95218 9.51807 9.81302 9.83301 9.81302C10.2285 9.81302 10.4775 10.0401 10.4775 10.4356V14.918C10.4775 15.2622 10.2651 15.5039 9.92822 15.5039ZM13.5244 15.5552C12.8506 15.5552 12.0962 15.1817 11.8838 14.5518C11.8252 14.4419 11.7812 14.3174 11.7812 14.1929C11.7812 13.9146 11.957 13.7388 12.2354 13.7388C12.4404 13.7388 12.5942 13.8487 12.7041 14.0684C12.8433 14.4126 13.1143 14.6763 13.5171 14.6763C14.0371 14.6763 14.3228 14.1783 14.3228 13.5923C14.3228 13.0576 14.0664 12.5523 13.5244 12.5523C13.2461 12.5523 13.019 12.6988 12.8579 12.9112C12.7041 13.0943 12.5942 13.1748 12.3599 13.1748C12.0083 13.1748 11.8325 12.9112 11.8545 12.5742L11.9937 10.5088C12.0156 10.106 12.2061 9.89358 12.6016 9.89358H14.7036C14.9746 9.89358 15.1431 10.0694 15.1431 10.3404C15.1431 10.5967 14.9746 10.7798 14.7036 10.7798H12.9019L12.7847 12.3618H12.814C13.0117 11.981 13.4146 11.7613 13.8906 11.7613C14.8428 11.7613 15.3848 12.5962 15.3848 13.5703C15.3848 14.7129 14.6743 15.5552 13.5244 15.5552Z"
      fill="white"
    />
  </svg>
</template>
