import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { Router } from "vue-router";

export const initSentry = (app: App, router: Router) => {
  const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
  if (!dsn) return;

  Sentry.init({
    app,
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/graphql-mesh.primedia-service\.com/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    // 1.0 => Capture 100% of the transactions, reduce in production!
    tracesSampleRate: 0.05,
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
