export const remainingTime = (progress: number, duration: number): string => {
  // progress is in percent
  // duration is total amount of time in seconds

  let remainingTime = "";
  const seconds = duration - (duration * progress) / 100;

  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (minutes > 60) {
    remainingTime = `${hours} hour(s) and ${minutes} min(s)`;
  } else if (seconds > 60) {
    remainingTime = `${minutes} min(s)`;
  } else {
    remainingTime = `${Math.round(seconds)} sec(s)`;
  }

  return remainingTime;
};

export const toHoursMinsSecs = (totalSecs: number) => {
  const totalMinutes = Math.floor(totalSecs / 60);

  const seconds = Math.floor(totalSecs % 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  return { hour: hours, min: minutes, sec: seconds };
};

export const UTCtoLocalTime = (date: number) => {
  const local = new Date(date * 1000);
  return `${local.getHours()}:${String(local.getMinutes()).padStart(2, "0")}`;
};

export const UTCDateToLocalDate = (date: string) => {
  const local = new Date(date);

  return `${local.getDay()} ${local.toLocaleString("default", {
    month: "long",
  })} ${local.getFullYear()}`;
};

export const timeZeroPadding = (time: number, paddingNo: number = 2) =>
  String(time).padStart(paddingNo, "0");

export const toFullYearFormatedDate = (date: Date = new Date()) => {
  const year = date.getFullYear();
  const month = timeZeroPadding(date.getMonth() + 1);
  const day = timeZeroPadding(date.getDate());

  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const nowInSec = () => new Date().getTime() / 1000;
