<script setup lang="ts">
import { useInfiniteScroll, useStorage } from "@vueuse/core";
import { loadMore, useHomeExplore } from "../graphql/composables/home-explore";
import { MediaType } from "../graphql/generated/client";

definePage({ meta: { pageName: "explore" } });

const buildNumber = import.meta.env.VITE_BUILD_NUMBER;

const filteredMedia = useStorage<MediaType>("content-filter", MediaType.All, sessionStorage);

const route = useRoute();

const { result, loading, error, lastId, hasNextPage, load, fetchMore } = useHomeExplore();

const onFilter = async (f: MediaType) => {
  filteredMedia.value = f;
};

const el = ref(null);

useInfiniteScroll(
  el,
  () => {
    if (!hasNextPage.value || loading.value) return;

    fetchMore({
      variables: { after: lastId.value },
      updateQuery: (previousQueryResult, options) => {
        return loadMore(previousQueryResult, options.fetchMoreResult);
      },
    });
  },
  { distance: 100 },
);

watch(
  filteredMedia,
  () => {
    load(filteredMedia.value);
  },
  { immediate: true },
);

onMounted(() => {
  if (route.query.msg) filteredMedia.value = MediaType.All;
});

const isProduction = import.meta.env.VITE_APP_ENV === "production";
</script>

<template>
  <Error v-if="error" :message="error.message" />

  <div
    v-else
    ref="el"
    class="-mr-4 h-full overflow-y-auto pt-4 lg:-mr-8 lg:pr-8 lg:pt-12"
    :class="{ 'pb-32': isProduction }"
  >
    <FilterBar :filter="filteredMedia" :on-filter="onFilter" />

    <SkeletonLoading v-if="(loading && !result) || !result" />

    <div v-else class="flex flex-col gap-6">
      <HeroReel v-if="!!result.heroReel.length" :teasers="result.heroReel" />
      <LivestreamReel
        v-if="!!result.liveStreamTeaserMediaIds.length"
        :teaser-media-ids="result.liveStreamTeaserMediaIds"
      />
      <ContinuePlayingReel />

        <MiniExplore :reels="result.reelList" :loading="loading" />
    </div>

    <div v-if="!isProduction" class="mt-16 p-4 text-right text-white/50">
      Build Number: {{ buildNumber }}
    </div>
  </div>
</template>
