<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M7.41667 4.5H18.0833C19.55 4.5 20.75 5.7 20.75 7.16667V17.8333C20.75 19.3 19.55 20.5 18.0833 20.5H7.41667C5.95 20.5 4.75 19.3 4.75 17.8333V7.16667C4.75 5.7 5.95 4.5 7.41667 4.5Z"
    />
  </svg>
</template>
