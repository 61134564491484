import { GetPreviewPodcastsQuery, useGetPreviewPodcastsQuery } from "../generated/client";

const mapResult = (result: GetPreviewPodcastsQuery) => {
  if (!result.umbraco.content.byType.audioPodcast?.items) return;

  return result.umbraco.content.byType.audioPodcast?.items.map((item): any => ({
    title: item?.headline || "",
    imageUrl: item?.headerImage || "",
    slug: item?._url,
  }));
};

export const useGetPreviewPodcasts = () => {
  const queryResult = useGetPreviewPodcastsQuery();

  const { error, loading } = queryResult;

  const result = computed(() => queryResult.result.value && mapResult(queryResult.result.value));

  return { result, loading, error };
};
