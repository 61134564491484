<script setup lang="ts">
import { usePlayerStore } from "../../store/player";
import { Station } from "../../types/prime";

type AudioProfile = {
  imageUrl?: string;
  title?: string;
  station?: Station;
};

defineProps<{ audioProfile: AudioProfile }>();

const audioStore = usePlayerStore();
</script>

<template>
  <div class="flex flex-col items-center">
    <StationContainer
      class="aspect-square w-[3rem] lg:w-[5.5rem]"
      :is-playing="audioStore.isPlaying"
      :station="audioProfile.station"
    >
      <CircularContainer
        class="h-full w-full"
        :class="{ 'pulse-overlay backdrop-blur-lg': audioStore.isPlaying }"
      >
        <CircularContainer
          class="aspect-square w-[2.6rem] lg:w-[4.3rem]"
          :class="{
            'pulse-overlay backdrop-blur-md': audioStore.isPlaying,
          }"
        >
          <CircularContainer
            class="aspect-square w-[2.25rem] lg:w-[4rem]"
            :class="{
              'pulse-overlay backdrop-blur-sm ': audioStore.isPlaying,
            }"
          >
            <Image
              :src="{
                lg: audioProfile.imageUrl || '',
                md: audioProfile.imageUrl || '',
                sm: audioProfile.imageUrl || '',
              }"
              :alt="audioProfile.title"
              class="h-full w-full max-w-[2.25rem] lg:max-w-[4rem]"
            />
          </CircularContainer>
        </CircularContainer>
      </CircularContainer>
    </StationContainer>
  </div>
</template>

<style scoped>
.pulse-overlay {
  background: rgba(255, 255, 255, 0.02);
}
</style>
