<script setup lang="ts">
import { useModalStore } from "./store/modal";
import { usePlayerStore } from "./store/player";
import { useUserStore } from "./store/user";

const userStore = useUserStore();
const route = useRoute();
const playerStore = usePlayerStore();

const modalStore = useModalStore();

onMounted(async () => {
  const user = await userStore.loadUser();

  if ((user && user.expired) || !user) {
    userStore.removeUser();
  }

  const evtSource = new EventSource(import.meta.env.VITE_APP_NOW_PLAYING_ENDPOINT);

  evtSource.onmessage = (e) => {
    playerStore.nowPlayingInfos = JSON.parse(e.data);
  };
});
</script>

<template>
  <MainLayout>
    <router-view :key="route.fullPath"></router-view>
  </MainLayout>
  <Regwall v-if="modalStore.regwall.isOpen" />
  <Paywall />
  <ErrorToast />
</template>
