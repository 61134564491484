<script setup lang="ts">
import { usePlayerStore } from "@/store/player";
import { PlayerType } from "@/store/player/type";
import * as player from "@/services/brightcove-player";

const playerStore = usePlayerStore();

const handlePlay = () => {
  if (playerStore.media) {
    player.play();
  }
};

const handlePause = () => {
  player.pause();
};

const handleRewind = () => {
  player.rewind(15);
};

const handleAdvance = () => {
  player.advance(15);
};

const miniplayerEl = ref<HTMLElement | null>(null);
</script>

<template>
  <footer
    ref="miniplayerEl"
    class="absolute bottom-0 left-[267px] right-0 z-20 transition-opacity duration-100"
    v-if="
      !$route.meta.hideMiniPlayer &&
      (playerStore.isLoading || !!playerStore.media.id) &&
      playerStore.currentInitiated === PlayerType.AUDIO
    "
  >
    <div class="hidden lg:flex">
      <DesktopMiniplayer
        :on-play="handlePlay"
        :on-rewind="handleRewind"
        :on-advance="handleAdvance"
        :on-pause="handlePause"
      />
    </div>
    <div class="lg:hidden">
      <MobileMiniplayer :on-play="handlePlay" :on-pause="handlePause" />
    </div>
  </footer>
</template>
