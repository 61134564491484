<script setup lang="ts">
import { useGetMyContent, useGetMyContentByIds } from "@/graphql/composables/my-content";
import { MyContentType } from "@/types/prime";
import { Switch } from "@headlessui/vue";
import { usePlayHistory } from "@/graphql/composables/play-history";
import { useUserStore } from "@/store/user";

definePage({
  meta: {
    requiresAuth: true,
    hideMobileNavigationBar: true,
    pageName: "my_content_bookmarks",
  },
});

const route = useRoute();
const myContentType = ref("");

if ("type" in route.params) {
  myContentType.value = route.params.type;
} else {
  throw new Error("No type found in route params");
}

const myContentDetailType = computed(() => {
  switch (myContentType.value) {
    case "followings":
      return MyContentType.FOLLOWING;
    case "bookmarks":
      return MyContentType.BOOKMARK;
    default:
      return MyContentType.ALL;
  }
});

const userStore = useUserStore();
const { result: playbackHistoryResult, load: loadPlaybackHistory } = usePlayHistory(
  userStore.userId ?? "",
);

const cardViewEnabled = ref(false);

const headline = computed(() => {
  switch (myContentDetailType.value) {
    case MyContentType.FOLLOWING:
      return "My bookmarked episodes";
    case MyContentType.BOOKMARK:
      return "My bookmarked shows";
    default:
      return "My Content";
  }
});

const {
  result: myContentIdsResult,
  loading: myContentIdsLoading,
  error: myContentIdsError,
  load: loadMyContentIds,
} = useGetMyContent(myContentDetailType.value);

const {
  result: myContentByIdsResult,
  loading: myContentByIdsLoading,
  error: myContentByIdsError,
  loadBookmarkContentByIds,
} = useGetMyContentByIds();

onMounted(() => {
  loadMyContentIds(undefined);
});

watch(myContentIdsResult, () => {
  loadBookmarkContentByIds(myContentIdsResult.value);
  userStore.userId && loadPlaybackHistory(undefined, { userId: userStore.userId });
});
</script>

<template>
  <SkeletonLoading v-if="myContentIdsLoading || myContentByIdsLoading" />

  <Error v-else-if="myContentIdsError || !myContentType || myContentByIdsError" />
  <div v-else class="-mx-4 h-full overflow-auto px-4 pb-32 lg:-mx-8 lg:px-8 lg:pt-14">
    <div class="sticky top-0 z-50 grid grid-cols-3 bg-prime-black py-2 lg:hidden">
      <router-link to="/my-content">
        <IconBack />
      </router-link>
      <p class="cursor-pointer justify-self-center whitespace-nowrap text-base font-bold">
        {{ headline }}
      </p>
    </div>

    <Transition name="fade">
      <section v-if="!cardViewEnabled">
        <BookmarkedEpisode
          v-for="result in myContentByIdsResult?.result ?? []"
          :key="result.mediaId"
          :episode="result"
          :playback-history="playbackHistoryResult?.find((i) => i.mediaId == result.mediaId)"
        />
      </section>

      <section v-else class="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        <div
          class="group relative aspect-square w-full shrink-0 overflow-hidden rounded-md bg-neutral-300 text-center text-primary"
          v-for="entry in myContentByIdsResult?.result"
        >
          <figure class="h-full">
            <Image
              :src="{
                lg: entry.imageUrl + '?width=208&height=208',
                md: entry.imageUrl + '?width=208&height=208',
                sm: entry.imageUrl + '?width=208&height=208',
              }"
              :alt="entry.headline"
              class="w-full object-cover"
            />
            <figcaption class="sr-only">
              {{ entry.headline }}
            </figcaption>
          </figure>
          <div class="gradient absolute inset-0"></div>
          <div class="absolute inset-0 flex h-full flex-col justify-end p-4">
            <h3 class="line-clamp-3 font-inter text-xs font-bold lg:text-base">
              <router-link :to="entry.url!">
                <span aria-hidden="true" class="absolute inset-0"></span>
                {{ entry.headline }}
              </router-link>
            </h3>
          </div>
        </div>
      </section>
    </Transition>
    <div class="fixed bottom-4 right-4">
      <div class="py-16">
        <Switch
          v-model="cardViewEnabled"
          class="relative inline-flex h-6 w-10 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-white transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          <span class="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            :class="cardViewEnabled ? 'translate-x-4' : 'translate-x-0'"
            class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-black shadow-lg ring-0 transition duration-200 ease-in-out"
          />
        </Switch>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  transition-delay: 500ms;
}
.gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.84) 100%);
}
</style>
