<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M12.0001 3C11.6591 3 11.3321 3.13546 11.091 3.37658C10.8499 3.6177 10.7144 3.94472 10.7144 4.28571C10.7144 4.62671 10.8499 4.95373 11.091 5.19485C11.3321 5.43597 11.6591 5.57143 12.0001 5.57143H22.2859C22.6268 5.57143 22.9539 5.43597 23.195 5.19485C23.4361 4.95373 23.5716 4.62671 23.5716 4.28571C23.5716 3.94472 23.4361 3.6177 23.195 3.37658C22.9539 3.13546 22.6268 3 22.2859 3H12.0001ZM5.57157 12C5.57157 11.659 5.70703 11.332 5.94815 11.0909C6.18926 10.8497 6.51629 10.7143 6.85728 10.7143H17.143C17.484 10.7143 17.811 10.8497 18.0521 11.0909C18.2933 11.332 18.4287 11.659 18.4287 12C18.4287 12.341 18.2933 12.668 18.0521 12.9091C17.811 13.1503 17.484 13.2857 17.143 13.2857H6.85728C6.51629 13.2857 6.18926 13.1503 5.94815 12.9091C5.70703 12.668 5.57157 12.341 5.57157 12ZM0.428711 19.7143C0.428711 19.3733 0.56417 19.0463 0.805288 18.8051C1.04641 18.564 1.37343 18.4286 1.71443 18.4286H12.0001C12.3411 18.4286 12.6682 18.564 12.9093 18.8051C13.1504 19.0463 13.2859 19.3733 13.2859 19.7143C13.2859 20.0553 13.1504 20.3823 12.9093 20.6234C12.6682 20.8645 12.3411 21 12.0001 21H1.71443C1.37343 21 1.04641 20.8645 0.805288 20.6234C0.56417 20.3823 0.428711 20.0553 0.428711 19.7143Z"
    />
  </svg>
</template>
