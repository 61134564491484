import { defineStore } from "pinia";

export const useErrorStore = defineStore({
  id: "error",
  state: (): { message: string; isShowing: boolean } => ({
    message: "",
    isShowing: false,
  }),
  actions: {
    show(message: string) {
      this.message = message;
      this.isShowing = true;
    },
    hide() {
      this.message = "";
      this.isShowing = false;
    },
  },
});
