<script setup lang="ts">
import { useStation } from "../../composables/station";
import { Station } from "../../types/prime";

const selectedStation = useStation();
</script>

<template>
  <ul
    class="mt-12 flex list-none flex-col gap-8 text-2xl font-extrabold lg:mt-14 lg:gap-6 lg:text-xl lg:font-medium"
  >
    <li>
      <router-link active-class="menu-item--active" to="/" class="menu-item">Explore</router-link>
    </li>
    <li class="py-1">
      <!-- Stations nested list -->
      <ul class="grid list-none grid-cols-4 gap-4 lg:flex lg:flex-col lg:gap-1">
        <li class="flex h-12 items-center">
          <router-link
            to="/station/947"
            @click="selectedStation = Station.NineFourSeven"
            v-slot="{ isActive }"
          >
            <StationMenu :value="Station.NineFourSeven" :is-selected="isActive" />
          </router-link>
        </li>
        <li class="flex h-12 items-center">
          <router-link
            to="/station/kfm"
            @click="selectedStation = Station.KFM"
            v-slot="{ isActive }"
          >
            <StationMenu :value="Station.KFM" :is-selected="isActive" />
          </router-link>
        </li>
        <li class="flex h-12 items-center">
          <router-link
            to="/station/capetalk"
            @click="selectedStation = Station.Capetalk"
            v-slot="{ isActive }"
          >
            <StationMenu :value="Station.Capetalk" :is-selected="isActive" />
          </router-link>
        </li>
        <li class="flex h-12 items-center">
          <router-link
            to="/station/702"
            @click="selectedStation = Station.SevenZeroTwo"
            v-slot="{ isActive }"
          >
            <StationMenu :value="Station.SevenZeroTwo" :is-selected="isActive" />
          </router-link>
        </li>
      </ul>
    </li>
    <li>
      <router-link active-class="menu-item--active " class="menu-item" to="/search">
        Search
      </router-link>
    </li>
    <li>
      <router-link active-class="menu-item--active " class="menu-item" to="/my-content">
        My Content
      </router-link>
    </li>
    <li>
      <router-link active-class="menu-item--active " class="menu-item" to="/settings">
        Settings
      </router-link>
    </li>
  </ul>
</template>

<style scoped>
.menu-item {
  position: relative;
}
.menu-item::before {
  border-radius: 4px;
  right: 100%;
  top: calc(50% - 4px);

  @apply absolute mr-2 h-2 w-2 scale-0 bg-white transition-transform duration-300 lg:content-[''];
}

.menu-item--active::before {
  @apply scale-100;
}
</style>
