<script setup lang="ts">
import * as audioPlayer from "../../services/brightcove-player";

type PlaybackbarProps = {
  progress: number;
  isMini?: boolean;
  isLive?: boolean;
};

defineProps<PlaybackbarProps>();

const onInputChange = (e: Event) => {
  const playbackBar = e.target as HTMLInputElement;
  audioPlayer.seek(+playbackBar.value);
};
</script>

<template>
  <div class="relative h-[3px] w-full">
    <div class="absolute inset-0 h-full rounded-full bg-neutral-700"></div>
    <div
      :style="{ width: `${progress}%` }"
      class="absolute inset-0 h-full rounded-full bg-white"
    ></div>
    <input
      type="range"
      :value="progress"
      min="0"
      max="100"
      step="any"
      v-if="!isLive"
      class="absolute inset-0 -ml-1 h-1.5 w-full cursor-pointer appearance-none bg-transparent accent-white"
      :class="{ 'mini-slider': isMini }"
      @input="onInputChange"
    />
  </div>
</template>

<style scoped>
.mini-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  cursor: pointer;
}

.mini-slider::-moz-range-thumb {
  display: none;
  appearance: none;
  width: 0;
  height: 0;
  cursor: pointer;
}
</style>
