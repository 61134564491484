import { InlinePlayerImage, MediaContentType } from "@/types/prime";

export const convertImageUrlSet = (
  imageUrl?: InlinePlayerImage,
  mediaType?: MediaContentType,
): string[] => {
  if (!imageUrl) return [];

  // type InlinePlayerImage infered
  const typedImageUrl = <InlinePlayerImage>imageUrl;

  if (mediaType === MediaContentType.AUDIO) {
    const srcset = [];

    typedImageUrl.audioLg && srcset.push(typedImageUrl.audioLg);
    typedImageUrl.audioMd && srcset.push(typedImageUrl.audioMd);
    typedImageUrl.audioSm && srcset.push(typedImageUrl.audioSm);

    return srcset;
  } else if (mediaType === MediaContentType.VIDEO) {
    const srcset = [];

    typedImageUrl.videoLg && srcset.push(typedImageUrl.videoLg);
    typedImageUrl.videoMd && srcset.push(typedImageUrl.videoMd);
    typedImageUrl.videoSm && srcset.push(typedImageUrl.videoSm);

    return srcset;
  }

  return [];
};
