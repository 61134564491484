if (typeof window !== "undefined") {
  // Ensure we can interact with the GPT command array.
  window.googletag = window.googletag || { cmd: [] };

  // Prepare GPT to display ads.
  window.googletag.cmd.push(() => {
    // Disable initial load, to precisely control when ads are requested.
    window.googletag.pubads().disableInitialLoad();

    // Enable SRA and services.
    window.googletag.pubads().enableSingleRequest();
    window.googletag.enableServices();
  });
}

export const state = { adSlotCount: 0, adSlots: {} as any };
