<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M9.06434 19.7144L17.5635 12.889C17.7004 12.779 17.8103 12.6425 17.8856 12.489C17.961 12.3354 18 12.1684 18 11.9994C18 11.8303 17.961 11.6633 17.8856 11.5097C17.8103 11.3562 17.7004 11.2197 17.5635 11.1097L9.06434 4.28429C8.25309 3.6329 7 4.17295 7 5.17396V18.8267C7 19.8277 8.25309 20.3678 9.06434 19.7144Z"
    />
  </svg>
</template>
