import { useErrorStore } from "@/store/error";
import { useModalStore } from "@/store/modal";
import { useUserStore } from "@/store/user";
import { UserManager, WebStorageStateStore, Log } from "oidc-client-ts";

const CALLBACK_URL = `${window.location.origin}/auth/callback`;
const POST_LOGOUT_URL = `${window.location.origin}/`;

const userManager = new UserManager({
  authority: import.meta.env.VITE_APP_AUTH_URL,
  client_id: import.meta.env.VITE_APP_AUTH_CLIENT_ID,

  redirect_uri: CALLBACK_URL,
  post_logout_redirect_uri: POST_LOGOUT_URL,

  response_type: "code",

  automaticSilentRenew: true,
  accessTokenExpiringNotificationTimeInSeconds: 5,

  userStore: new WebStorageStateStore({ store: window.localStorage }),

  scope: "openid profile email offline_access",
});

userManager.events.addSilentRenewError((e) => {
  forceLogout();
});

export const login = async () => {
  const errorState = useErrorStore();

  try {
    return await userManager.signinRedirect();
  } catch (e) {
    errorState.show("unable to login, please try again later");
  }
};

export const changePassword = () => {
  return userManager.signinRedirect({ extraQueryParams: { kc_action: "UPDATE_PASSWORD" } });
};

export const logout = () => {
  return userManager.signoutRedirect();
};

export const removeUser = () => {
  return userManager.removeUser();
};

export const isLoggedIn = async () => {
  const user = await userManager.getUser();
  return !!user;
};

export const getAccessToken = async () => {
  const user = await userManager.getUser();
  return user?.access_token;
};

export const getUser = async () => userManager.getUser();

export const handleCallback = async () => {
  return userManager.signinRedirectCallback(window.location.href);
};

export const deleteAccount = () => {
  return userManager.signinRedirect({ extraQueryParams: { kc_action: "delete_account" } });
};

export const slientSignin = async () => {
  return userManager.signinSilent();
};

export const forceLogout = () => {
  const userStore = useUserStore();
  userStore.removeUser();

  const modalStore = useModalStore();
  modalStore.openRegwall();
};
