import { Station } from "@/types/prime";

type MediaProfile = {
  imageUrl?: string;
  title?: string;
  station?: Station;
};

export const addMediaSession = (media: MediaProfile) => {
  if (!media) return;
  if ("mediaSession" in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: media.station,
      artist: media.title,
      album: "Primedia +",
      artwork: [
        {
          src: `${media.imageUrl}`,
          sizes: "180x180",
          type: "image/jpg",
        },
      ],
    });
  }
};
