import { StationEnum } from "@/graphql/generated/client";
import type { Station } from "../../types/prime";

export enum PlayerType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export type Media = {
  duration: number;
  currentTime: number; // in sec
  startTime: number; // in sec
  id: string;
};

export type NowPlayingInfo = {
  title: string;
  artist: string;
  duration: string;
  start: string;
  end: string;
};

export type PlayerState = {
  audio: {
    title: string;
    station?: Station;
    imageUrl: string;
  };
  isPlaying: boolean;
  isLoading: boolean;
  currentInitiated?: PlayerType;
  media: Media;
  isLive: boolean;
  nowPlayingInfos: { station: StationEnum; items: NowPlayingInfo[] }[] | null;
};
