import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  from,
} from "@apollo/client/core";
import * as authService from "@/services/auth";
import { onError } from "@apollo/client/link/error";

enum ErrorCodes {
  AUTH_NOT_AUTHORIZED = "AUTH_NOT_AUTHORIZED",
}

const cache = new InMemoryCache({
  typePolicies: {
    SearchQuery: {
      keyFields: ["query"],
    },
  },
});

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_APP_GRAPHQL_ENDPOINT,
  headers: { "x-app-name": "prime", "x-app-platform": "web" },
});

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.code === ErrorCodes.AUTH_NOT_AUTHORIZED) {
        authService.forceLogout();
      }
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    authService
      .getAccessToken()
      .then((token) => {
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : "",
          },
        });
        observer.next(undefined); // next complains if we don't pass anything
        observer.complete();
      })
      .catch((error) => {
        observer.error(error);
      });
  }).flatMap(() => {
    return forward(operation);
  });
});

const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, authMiddleware, httpLink]),
});

export default apolloClient;
