<script setup lang="ts">
import { usePlayerStore } from "@/store/player";
import { Station } from "../../types/prime";

const props = defineProps<{ isSelected: boolean; value: Station }>();
const playerStore = usePlayerStore();

const stationMenuItemClass = computed(() => {
  if (playerStore.isPlaying && playerStore.audio.station === props.value) {
    return "lg:animate-ping duration-2000";
  } else if (props.isSelected) {
    return "lg:scale-100 duration-300";
  }
  return "scale-0";
});
</script>

<template>
  <span class="-ml-4 flex flex-row items-center gap-2">
    <span
      class="block h-2 w-2 rounded-full opacity-0 transition-transform lg:opacity-100"
      :class="[stationMenuItemClass, `bg-theme-${value}`]"
    ></span>
    <img :src="`/images/logos/logo-${value}.png`" alt="" class="w-12" />
  </span>
</template>

<style scoped>
.duration-2000 {
  animation-duration: 2000ms;
}
</style>
