<script setup lang="ts">
import { toHoursMinsSecs } from "../../utilities/timeUtils";
import * as player from "@/services/brightcove-player";
import { usePlayerStore } from "../../store/player";
import { addMediaSession } from "../../services/mediaSession";

defineProps<{
  onPlay: () => void;
  onPause: () => void;
  onAdvance: () => void;
  onRewind: () => void;
}>();

const playerStore = usePlayerStore();

const duration = computed(() => toHoursMinsSecs(playerStore.media.duration));
const progressed = computed(() => toHoursMinsSecs(playerStore.media.currentTime));

const onStop = () => {
  player.pause();
  playerStore.reset();
};

addMediaSession(playerStore.audio);
</script>

<template>
  <div class="grid w-full grid-cols-4 gap-4 bg-prime-black/10 px-10 py-4 backdrop-blur-xl">
    <div class="col-span-1 flex flex-row items-center gap-2">
      <AudioProfile
        :audio-profile="{
          imageUrl: playerStore.audio?.imageUrl,
          station: playerStore.audio?.station,
          title: playerStore.audio?.title,
        }"
      />
      <div class="flex w-full flex-col overflow-hidden">
        <AutoScrollText v-if="playerStore.audio?.title" class="text-lg font-bold">
          {{ playerStore.audio.title }}
        </AutoScrollText>
      </div>
    </div>
    <div class="col-span-3 flex flex-row items-center gap-2">
      <IconRewind class="w-8 cursor-pointer" @click="onRewind" v-if="!playerStore.isLive" />
      <IconSpinner v-if="playerStore.isLoading" class="w-8 animate-spin cursor-wait" />
      <template v-else>
        <IconStopSquare
          v-if="playerStore.isPlaying && playerStore.isLive"
          class="w-8 cursor-pointer"
          @click="onStop"
        />
        <IconPause v-else-if="playerStore.isPlaying" class="w-8 cursor-pointer" @click="onPause" />
        <IconPlay v-else class="w-8 cursor-pointer" @click="onPlay" />
      </template>
      <IconForward class="w-8 cursor-pointer" @click="onAdvance" v-if="!playerStore.isLive" />
      <div class="flex w-full flex-row items-center justify-between gap-4">
        <Time class="w-[80px] text-base font-normal opacity-50" :time="progressed" />
        <PlaybackBar
          class="w-full flex-grow"
          :progress="playerStore.progressPercent"
          :is-live="playerStore.isLive"
        />
        <Time class="w-[80px] text-base font-normal opacity-50" :time="duration" />
      </div>
    </div>
  </div>
</template>
