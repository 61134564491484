<script setup lang="ts">
import { useStation } from "@/composables/station";
import { Station } from "@/types/prime";

definePage({ meta: { pageName: "station_selection" } });

const stations = Object.values(Station).filter((station) => station !== Station.None);
const selectedStation = useStation();
</script>

<template>
  <main class="relative mx-auto max-w-[930px] lg:px-10">
    <h1 class="mb-11 mt-14 font-inter text-4xl font-bold tracking-heading lg:block lg:text-5xl">
      Stations
    </h1>
    <ul class="flex flex-wrap">
      <li
        v-for="(value, index) in stations"
        :key="index"
        :class="index === 0 || index === 3 ? 'w-full' : 'w-1/2'"
        class="p-2"
      >
        <router-link
          :to="`/station/${value}`"
          class="flex h-40 w-full items-center justify-center rounded-[15px] p-4 transition-colors hover:bg-neutral-125"
          :class="selectedStation === value ? 'bg-neutral-125' : 'bg-neutral-interaction'"
          @click="selectedStation = value"
        >
          <img :src="`/images/logos/logo-${value}.png`" />
        </router-link>
      </li>
    </ul>
  </main>
</template>
