<script setup lang="ts">
import { useModalStore } from "../../store/modal";
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from "@headlessui/vue";

const modalStore = useModalStore();

const benefits = ["All Access", "No Ads", "Downloads", "Cancel Anytime"];

// will be replaced
const paywallImages = [
  "/images/paywall/paywall-image-1.jpg",
  "/images/paywall/paywall-image-2.jpg",
  "/images/paywall/paywall-image-3.jpg",
  "/images/paywall/paywall-image-4.jpg",
  "/images/paywall/paywall-image-5.jpg",
  "/images/paywall/paywall-image-6.jpg",
  "/images/paywall/paywall-image-1.jpg",
  "/images/paywall/paywall-image-2.jpg",
  "/images/paywall/paywall-image-3.jpg",
  "/images/paywall/paywall-image-4.jpg",
  "/images/paywall/paywall-image-5.jpg",
  "/images/paywall/paywall-image-6.jpg",
  "/images/paywall/paywall-image-1.jpg",
  "/images/paywall/paywall-image-2.jpg",
  "/images/paywall/paywall-image-3.jpg",
  "/images/paywall/paywall-image-4.jpg",
  "/images/paywall/paywall-image-5.jpg",
  "/images/paywall/paywall-image-6.jpg",
];

const closeModal = () => {
  modalStore.paywall.isOpen = false;
};
</script>

<template>
  <TransitionRoot appear :show="modalStore.paywall.isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <div class="fixed inset-0 bg-register-portrait bg-cover bg-center bg-no-repeat">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="h-full transition-all">
            <div class="relative h-full lg:flex lg:justify-center">
              <div class="absolute right-12 top-12 z-10 hidden cursor-pointer justify-end lg:flex">
                <IconClose @click="closeModal" />
              </div>
              <div class="relative h-full w-full pb-11">
                <div class="mx-8 mb-2 mt-6 grid grid-cols-3 lg:hidden">
                  <IconClose @click="closeModal" />
                  <span class="justify-self-center text-base font-bold">Audiostar</span>
                </div>
                <div class="h-full overflow-auto lg:mx-auto lg:max-w-6xl">
                  <div class="relative">
                    <div class="relative">
                      <img
                        src="http://bp-prime-plus-umbraco-cms-dev.azurewebsites.net/media/4cndrsyl/kfm_mornings_16-9.png"
                      />
                      <div class="gradient absolute inset-0"></div>
                    </div>
                    <div
                      class="relative flex w-full -translate-y-8 overflow-x-hidden lg:absolute lg:-translate-y-full"
                    >
                      <ul class="animation flex gap-4">
                        <li
                          v-for="image in paywallImages"
                          class="shrink-0 overflow-hidden rounded-lg first:ml-4"
                        >
                          <Image :src="{ lg: image, md: image, sm: image }" class="w-20 md:w-32" />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="mt-4 px-8 pb-11 md:mx-auto md:max-w-lg lg:mt-12">
                    <h1 class="mb-4 text-4xl font-extrabold md:text-5xl lg:mb-6">
                      Upgrade to Audiostar+
                    </h1>
                    <ul class="mb-4 columns-2 text-lg font-bold lg:px-8">
                      <li
                        v-for="benefit in benefits"
                        class="flex items-center whitespace-nowrap py-2"
                      >
                        <IconCheckCircle class="mr-1 flex-shrink-0 text-success" />
                        {{ benefit }}
                      </li>
                    </ul>

                    <div class="space-y-2">
                      <Button size="large" theme="secondary">Try free for 7 days</Button>
                      <p class="text-center text-xs text-success">After 7 days 100 ZAR / month</p>

                      <Button size="large" theme="tertiary">Restore Purchases</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.animation {
  animation: marquee 200s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(
      calc(
        -1 * (((93.969262vh + 34.202014vw) / 8 - 24px + 24px) * 8 +
              (1.78 * ((93.969262vh + 34.202014vw) / 8 - 24px) + 24px) * 3)
      ),
      0,
      0
    );
  }
}

.gradient {
  background: linear-gradient(179.94deg, rgba(16, 16, 16, 0) -15.96%, #101010 89.57%);
}
</style>
