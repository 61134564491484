<script setup lang="ts">
import { useModalStore } from "@/store/modal";
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from "@headlessui/vue";
import * as authService from "@/services/auth";
import { useRedirectUrl } from "@/composables/redirect-url";

const redirectUrl = useRedirectUrl();
const route = useRoute();
const modalStore = useModalStore();

import { useGetPreviewPodcasts } from "../../graphql/composables/getPreviews";
const { result: items } = useGetPreviewPodcasts();

const result = computed(() => {
  if (items.value) {
    return items.value.sort(() => Math.random() - 0.5);
  }
});

const firstRow = computed(() => {
  if (result.value) {
    return result.value.slice(0, 19);
  }
});

const secondRow = computed(() => {
  if (result.value) {
    return result.value.slice(20, 40);
  }
});

const closeModal = () => {
  modalStore.regwall.isOpen = false;
};

// will be replaced
const regwallImages = [
  "/images/paywall/paywall-image-1.jpg",
  "/images/paywall/paywall-image-2.jpg",
  "/images/paywall/paywall-image-3.jpg",
  "/images/paywall/paywall-image-4.jpg",
  "/images/paywall/paywall-image-5.jpg",
  "/images/paywall/paywall-image-6.jpg",
  "/images/paywall/paywall-image-1.jpg",
  "/images/paywall/paywall-image-2.jpg",
  "/images/paywall/paywall-image-3.jpg",
  "/images/paywall/paywall-image-4.jpg",
  "/images/paywall/paywall-image-5.jpg",
  "/images/paywall/paywall-image-6.jpg",
  "/images/paywall/paywall-image-1.jpg",
  "/images/paywall/paywall-image-2.jpg",
  "/images/paywall/paywall-image-3.jpg",
  "/images/paywall/paywall-image-4.jpg",
  "/images/paywall/paywall-image-5.jpg",
  "/images/paywall/paywall-image-6.jpg",
];

watch(
  () => modalStore.regwall.isOpen,
  (isOpen) => {
    isOpen && (redirectUrl.value = route.path);
  },
);
</script>

<template>
  <TransitionRoot appear :show="modalStore.regwall.isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <div
        class="fixed inset-0 bg-register-portrait bg-cover bg-center bg-no-repeat backdrop-blur-xl lg:bg-register-landscape"
      >
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="h-full transition-all">
            <div class="relative h-full lg:flex lg:justify-center lg:pb-16">
              <div class="absolute right-[50px] top-[50px] z-50 hidden justify-end lg:flex">
                <IconClose class="cursor-pointer" @click="closeModal" />
              </div>
              <div class="relative h-full w-full px-8 pb-11 pt-6">
                <div
                  class="pointer-events-none absolute inset-0 top-6 -z-10 flex justify-start lg:top-32"
                >
                  <div class="opacity-40 blur lg:block lg:-rotate-12 lg:opacity-60">
                    <ul class="animation flex gap-4">
                      <li
                        v-for="(image, index) in firstRow"
                        :key="index"
                        class="shrink-0 overflow-hidden rounded-xl first:ml-4"
                      >
                        <Image
                          :src="{
                            lg: image.imageUrl._url,
                            md: image.imageUrl._url,
                            sm: image.imageUrl._url,
                          }"
                          :alt="image.title"
                          class="w-40 lg:w-52"
                        />
                      </li>
                    </ul>
                    <ul class="animation animation--backwards mt-4 flex gap-4">
                      <li
                        v-for="(image, index) in secondRow"
                        class="shrink-0 overflow-hidden rounded-xl first:ml-4"
                      >
                        <Image
                          :src="{
                            lg: image.imageUrl._url,
                            md: image.imageUrl._url,
                            sm: image.imageUrl._url,
                          }"
                          :alt="image.title"
                          class="w-40 lg:w-52"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="grid grid-cols-3 lg:hidden">
                  <IconClose class="cursor-pointer" @click="closeModal" />
                </div>
                <div
                  class="mx-auto flex h-full flex-col justify-between overflow-auto lg:max-w-[454px]"
                >
                  <div class="flex flex-1 flex-col justify-end gap-12">
                    <h1 class="text-5xl font-extrabold">Register to Continue</h1>
                    <div class="flex flex-col gap-4">
                      <WallItem title="Bookmark" subtitle="Save anything for later" />
                      <WallItem title="Follow" subtitle="Always know about the latest" />
                      <WallItem
                        title="Notifications"
                        subtitle="Always stay on top of your content"
                      />
                    </div>
                  </div>
                  <div class="mt-10 flex w-full flex-col gap-6 px-1.5">
                    <Button
                      @click.stop=""
                      size="large"
                      theme="primary"
                      @click="authService.login()"
                    >
                      Join for free
                    </Button>
                    <span class="text-center text-sm font-normal text-neutral-500">
                      Already have an account?
                      <button @click="authService.login()" class="font-bold text-white">
                        Log in
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.animation {
  animation: marquee 400s linear infinite;
}
.animation--backwards {
  animation-direction: reverse;
  transform-origin: right;
  animation: marquee-backwards 400s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(
      calc(
        -1 * (((93.969262vh + 34.202014vw) / 8 - 24px + 24px) * 8 +
              (1.78 * ((93.969262vh + 34.202014vw) / 8 - 24px) + 24px) * 3)
      ),
      0,
      0
    );
  }
}

@keyframes marquee-backwards {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(
      calc(
        -1 * (
            ((93.969262vh + 34.202014vw) / 8 - 24px + 24px) * 8 +
              (1.78 * ((93.969262vh + 34.202014vw) / 8 - 24px) + 24px) * 3
          ) * -1
      ),
      0,
      0
    );
  }
}
</style>
