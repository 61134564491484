<script setup lang="ts">
import { useDetailPageByUrl } from "@/graphql/composables/getDetailByUrl";
import { DetailPageType, type CompetitionDetailPage } from "@/types/prime";

definePage({ meta: { pageName: "microsite" } });

const route = useRoute("/sites/[name]");

const pageName = route.params.name;

const { error, loading, result } = useDetailPageByUrl("/" + pageName);

const hideTitleOnMobile = computed(() => route.query.hideMobileNavBar);

onMounted(() => {
  let otConsentSdk = document.getElementById("onetrust-consent-sdk");
  if (hideTitleOnMobile && otConsentSdk) {
    otConsentSdk.remove();
  }
});
</script>

<template>
  <Error :headline="error.name" v-if="error" />
  <SkeletonLoading v-else-if="loading" />

  <div
    v-else-if="result"
    class="-mx-4 h-full overflow-auto px-4 pb-8 lg:-mx-8 lg:px-8"
    :class="!hideTitleOnMobile ? 'pt-16 lg:pt-8' : 'pt-4'"
  >
    <h1 v-if="!hideTitleOnMobile" class="mb-2 text-lg font-bold lg:mb-4 lg:text-3xl">
      {{ result?.headline }}
    </h1>
    <article v-html="result.content" />
  </div>
</template>
