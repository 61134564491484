import { defineStore } from "pinia";
import { ModalState } from "./type";

export const useModalStore = defineStore({
  id: "modal",
  state: (): ModalState => ({ paywall: { isOpen: false }, regwall: { isOpen: false } }),
  actions: {
    openRegwall() {
      this.regwall.isOpen = true;
    },
    openPaywall() {
      this.paywall.isOpen = true;
    },
  },
});
