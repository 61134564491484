import { defineStore } from "pinia";
import { PlayerState, PlayerType } from "./type";
import { Station } from "../../types/prime";

const defaultPlayer = {
  audio: {
    imageUrl: "",
    station: undefined,
    title: "",
  },
  currentInitiated: undefined,
  isLoading: false,
  isPlaying: false,
  media: { id: "", duration: 0, currentTime: 0, startTime: 0 },
  isLive: false,
  nowPlayingInfos: null,
};

export const usePlayerStore = defineStore({
  id: "player",
  state: (): PlayerState => defaultPlayer,
  getters: {
    progressPercent: (state) =>
      state.media ? (state.media.currentTime / state.media.duration) * 100 : 0,
    isActive: (state) =>
      (state.isLoading || !!state.media.id) && state.currentInitiated === PlayerType.AUDIO,
  },
  actions: {
    setAudioInfo(title: string, station: Station, imageUrl: string, duration?: number) {
      this.audio.title = title;
      this.audio.station = station;
      this.audio.imageUrl = imageUrl;

      duration && (this.media.duration = duration);
    },
    reset() {
      this.media.currentTime = 0;
      this.media.duration = 0;
      this.media.id = "";

      this.isPlaying = false;
      this.isLoading = false;
      this.isLive = false;
    },
  },
});
