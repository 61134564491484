<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.89998 5.7001C7.58038 5.7001 5.69998 7.5805 5.69998 9.9001C5.69998 12.2197 7.58038 14.1001 9.89998 14.1001C12.2196 14.1001 14.1 12.2197 14.1 9.9001C14.1 7.5805 12.2196 5.7001 9.89998 5.7001ZM3.59998 9.9001C3.59998 6.4207 6.42058 3.6001 9.89998 3.6001C13.3794 3.6001 16.2 6.4207 16.2 9.9001C16.2 11.2607 15.7686 12.5206 15.0353 13.5505L20.0924 18.6076C20.5025 19.0177 20.5025 19.6825 20.0924 20.0926C19.6824 20.5026 19.0176 20.5026 18.6075 20.0926L13.5503 15.0354C12.5205 15.7688 11.2606 16.2001 9.89998 16.2001C6.42058 16.2001 3.59998 13.3795 3.59998 9.9001Z"
    />
  </svg>
</template>
