<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
  >
    <path
      d="M10 16C10.2833 16 10.521 15.904 10.713 15.712C10.9043 15.5207 11 15.2833 11 15V8.975C11 8.69167 10.9043 8.45833 10.713 8.275C10.521 8.09167 10.2833 8 10 8C9.71667 8 9.47933 8.09567 9.288 8.287C9.096 8.479 9 8.71667 9 9V15.025C9 15.3083 9.096 15.5417 9.288 15.725C9.47933 15.9083 9.71667 16 10 16ZM14 16C14.2833 16 14.521 15.904 14.713 15.712C14.9043 15.5207 15 15.2833 15 15V8.975C15 8.69167 14.9043 8.45833 14.713 8.275C14.521 8.09167 14.2833 8 14 8C13.7167 8 13.4793 8.09567 13.288 8.287C13.096 8.479 13 8.71667 13 9V15.025C13 15.3083 13.096 15.5417 13.288 15.725C13.4793 15.9083 13.7167 16 14 16ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22Z"
    />
  </svg>
</template>
