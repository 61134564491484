<script setup lang="ts">
import { Station } from "@/types/prime";
import { usePlayerStore } from "../../store/player";
import * as player from "@/services/brightcove-player";

const playStore = usePlayerStore();

defineProps<{
  onPlay: () => void;
  onPause: () => void;
}>();

const playerStore = usePlayerStore();

const onStop = () => {
  player.pause();
  playerStore.reset();
};
</script>

<template>
  <div
    class="fixed inset-x-4 bottom-4 overflow-hidden rounded-md bg-neutral-overlay/60 backdrop-blur-[50px]"
  >
    <div class="px-2">
      <div class="my-1.5 flex flex-row justify-between">
        <div class="flex flex-row items-center gap-2">
          <AudioProfile
            :audio-profile="{
              imageUrl: playerStore.audio?.imageUrl,
              station: playerStore.audio?.station,
              title: playerStore.audio?.title,
            }"
          />
          <div class="flex flex-col overflow-hidden">
            <span v-if="playStore.audio?.title" class="text-xs font-bold">
              {{ playStore.audio.title }}
            </span>
          </div>
        </div>
        <div class="flex flex-row items-center">
          <IconSpinner v-if="playerStore.isLoading" class="w-6 animate-spin cursor-wait" />
          <template v-else>
            <IconStopCircle
              class="cursor-pointer"
              v-if="playStore.isPlaying && playerStore.isLive"
              @click="onStop"
            />
            <IconPauseCircle
              class="cursor-pointer"
              v-else-if="playStore.isPlaying"
              @click="onPause"
            />
            <IconPlayCircle class="cursor-pointer" v-else @click="onPlay" />
          </template>
        </div>
      </div>

      <PlaybackBar :progress="playStore.progressPercent" is-mini />
    </div>
  </div>
</template>

<style scoped></style>
