<script setup lang="ts">
import {
  DetailPageType,
  MyContentType,
  PodcastDetailPage,
  RadioShowDetailPage,
  NewsDetailPage,
  MediaContentType,
} from "@/types/prime";
import { usePlayerStore } from "@/store/player";
import { PlayerType } from "@/store/player/type";
import { useMiniExplore } from "@/graphql/composables/mini-explore";
import { useAsyncSetBookmark, useAsyncDeleteBookmark } from "@/graphql/composables/bookmark";
import { useGetMyContent } from "@/graphql/composables/my-content";
import { useErrorStore } from "@/store/error";
import { DeleteBookmarkMutation, SetBookmarkMutation } from "@/graphql/generated/client";
import { useSetSeoData } from "@/composables/seo-data";
import { usePlayHistory } from "@/graphql/composables/play-history";
import { isLoggedIn } from "@/services/auth";
import { useModalStore } from "@/store/modal";
import { useUserStore } from "@/store/user";
import * as player from "@/services/brightcove-player";
import { useDetailPageByUrl } from "@/graphql/composables/getDetailByUrl";

definePage({ meta: { pageName: "detail_page" } });

// end of blockquote embeds

const pageURL = ref("");
const detailPageType = ref<DetailPageType>();
const route = useRoute();
const el = ref<HTMLElement | null>(null);

if ("path" in route.params) {
  pageURL.value = route.params.path;
} else {
  throw new Error("No id found in route params");
}

const userStore = useUserStore();

const { result, loading, error: resultError } = useDetailPageByUrl("/" + pageURL.value);
const { miniExploreLoading, miniExploreResult, loadMiniExplore } = useMiniExplore(el);

const notFoundError = ref<Error>();

const error = computed(() => resultError.value || notFoundError.value);

const { result: playbackHistory, load: loadPlaybackHistory } = usePlayHistory(
  userStore.userId ?? "",
  "",
);

watch(
  () => result.value,
  () => {
    if (result.value) {
      detailPageType.value = result.value.type;

      loadMiniExplore(result.value.id);
      useSetSeoData(result.value);
    } else {
      notFoundError.value = {
        name: "not found",
        message: "the specific detail page could be found!",
      };
    }
  },
);

watch([result, () => userStore.userId], () => {
  if (result.value) {
    detailPageType.value = result.value.type;

    loadMiniExplore(result.value.id);
    useSetSeoData(result.value);

    userStore.userId &&
      result.value.mediaId &&
      loadPlaybackHistory(undefined, {
        userId: userStore.userId,
        videoIds: result.value.mediaId,
      });
  }
});

const discussEnabled = computed(
  () => import.meta.env.VITE_APP_DISCUSS_ENABLED === "true" && !!route.query.discuss,
);

const hasPlayer = computed(() => {
  return (
    (detailPageType.value == DetailPageType.VIDEODETAIL && result.value?.mediaId) ||
    (detailPageType.value == DetailPageType.AUDIODETAIL && result.value?.mediaId)
  );
});

const detailPageID = computed(() => {
  return result.value?.id as string;
});

const isAudio = computed(() => result.value?.contentType === MediaContentType.AUDIO);

const playerStore = usePlayerStore();
const errorStore = useErrorStore();

const isPlaying = computed(
  () => playerStore.isPlaying && isAudio && playerStore.media?.id === result.value?.mediaId,
);

const onPlay = () => {
  if (!result.value?.mediaId) return;

  player.loadAndPlay(
    result.value.mediaId,
    isAudio.value ? PlayerType.AUDIO : PlayerType.VIDEO,
    result.value.headline,
    result.value.station,
    result.value.headerImage.sm,
    playbackHistory.value?.[0]?.elapsedSec,
  );
};

const { result: bookmarkedResult, load: loadBookmarked } = useGetMyContent(MyContentType.ALL);

const isBookmarked = ref<undefined | boolean>(undefined);
watch(bookmarkedResult, () => {
  isBookmarked.value = bookmarkedResult.value?.includes(detailPageID.value);
});

onMounted(async () => {
  if (await isLoggedIn()) {
    loadBookmarked();
  } else isBookmarked.value = false;
});

const bookmarkLoading = computed(() => {
  return (
    loadingSetBookmark.value || loadingDeleteBookmark.value || isBookmarked.value === undefined
  );
});

const handleSetBookmarkResult = (
  myContent?: SetBookmarkMutation | null,
  successStatusCode: number = 200,
) => {
  const isSuccess = myContent?.userPreferences.add.httpStatusCode === successStatusCode;

  if (isSuccess) {
    isBookmarked.value = true;
  } else {
    errorStore.show(myContent?.userPreferences.add.message || "Could not add bookmark");
  }
};

const handleDeleteBookmarkResult = (
  myContent?: DeleteBookmarkMutation | null,
  successStatusCode: number = 200,
) => {
  const isSuccess = myContent?.userPreferences.delete.httpStatusCode === successStatusCode;

  if (isSuccess) {
    isBookmarked.value = false;
  } else {
    errorStore.show(myContent?.userPreferences.delete.message || "Could not delete bookmark");
  }
};

const {
  mutate: setBookmark,
  onError: onErrorSetBookmark,
  loading: loadingSetBookmark,
  onDone: onDoneSetBookmark,
} = useAsyncSetBookmark(detailPageID.value, "/" + pageURL.value, detailPageType.value!);

const {
  mutate: deleteBookmark,
  onError: onErrorDeleteBookmark,
  loading: loadingDeleteBookmark,
  onDone: onDoneDeleteBookmark,
} = useAsyncDeleteBookmark(detailPageID.value);

const setBookmarkClick = async () => {
  const modalStore = useModalStore();
  if (!(await isLoggedIn())) {
    return modalStore.openRegwall();
  }

  if (!isBookmarked.value) {
    setBookmark({
      id: detailPageID.value,
      path: "/" + pageURL.value,
      typeName: detailPageType.value!,
    });

    onDoneSetBookmark((result) => {
      handleSetBookmarkResult(result.data, 200);
    });
  } else {
    deleteBookmark({ contentId: detailPageID.value! });

    onDoneDeleteBookmark((result) => {
      handleDeleteBookmarkResult(result.data, 200);
    });
  }

  onErrorSetBookmark((error) => {
    errorStore.show(error.message);
  });

  onErrorDeleteBookmark((error) => {
    errorStore.show(error.message);
  });
};
</script>

<template>
  <div>
    <NotFoundError :message="error.message" :headline="error.name" v-if="error" />

    <div v-else ref="el" class="-mx-4 h-screen overflow-auto overflow-x-hidden pb-32 lg:-mx-8">
      <article>
        <DetailPageHeader
          :header-image-url="result?.headerImage || { lg: '', md: '', sm: '' }"
          :cover-image-url="result?.coverImage || { lg: '', md: '', sm: '' }"
          :alt="result?.headline"
          :ratio="isAudio ? 'square' : 'landscape'"
          :is-loading="loading"
          :is-article="detailPageType === DetailPageType.NEWSDETAIL"
        >
          <template #actions>
            <div class="mr-16 cursor-pointer" v-if="hasPlayer">
              <IconSpinner
                class="h-12 w-12 animate-spin cursor-wait"
                v-if="playerStore.isLoading"
              />
              <template v-else>
                <IconPlayCircle v-if="!isPlaying" class="h-12 w-12" @click="onPlay" />
                <IconPauseCircle v-else class="h-12 w-12" @click="player.pause" />
              </template>
            </div>
            <ToggleButton
              v-if="detailPageID"
              class="w-max"
              unchecked-name="Bookmark"
              checked-name="Bookmarked"
              unchecked-icon-name="Bookmark"
              checked-icon-name="Haken"
              :is-checked="isBookmarked"
              :loading="bookmarkLoading"
              @click="setBookmarkClick"
            />
          </template>
        </DetailPageHeader>

        <template v-if="!loading && result">
          <div
            class="my-8 text-center lg:hidden"
            v-if="
              detailPageType === DetailPageType.AUDIODETAIL ||
              detailPageType === DetailPageType.VIDEODETAIL
            "
          >
            <Button class="w-max cursor-wait text-center" v-if="playerStore.isLoading" size="large">
              <IconSpinner class="h-6 w-6 animate-spin" />Loading
            </Button>
            <template v-else>
              <Button v-if="!isPlaying" @click="onPlay" class="w-max" size="large">
                <IconPlay />Play
              </Button>
              <Button v-else @click="player.pause" class="w-max" size="large">
                <IconPause />Pause
              </Button>
            </template>
          </div>

          <hgroup class="not-prose max-w-[930px] px-4 md:px-8 lg:px-12">
            <span
              v-if="result?.overline"
              class="mb-1 line-clamp-1 text-sm font-medium md:text-lg"
              >{{ result.overline }}</span
            >
            <h1 class="mb-2 text-2xl font-extrabold md:text-3xl">{{ result.headline }}</h1>
            <h2 v-if="result.subline" class="mb-6 line-clamp-4 whitespace-pre-line text-lg">
              {{ result.subline }}
            </h2>
          </hgroup>

          <main class="max-w-[930px] px-4 md:px-8 lg:px-12">
            <template
              v-if="
                detailPageType === DetailPageType.AUDIODETAIL ||
                detailPageType === DetailPageType.VIDEODETAIL
              "
            >
              <MediaDetailContent :data="result">
                <template #detailPageActions>
                  <div class="mt-7 flex flex-row gap-2 lg:hidden">
                    <ToggleButton
                      v-if="detailPageID"
                      class="w-max"
                      unchecked-name="Bookmark"
                      checked-name="Bookmarked"
                      unchecked-icon-name="Bookmark"
                      checked-icon-name="Haken"
                      :is-checked="isBookmarked"
                      :loading="bookmarkLoading"
                      v-protected:click="setBookmarkClick"
                    />
                    <SocialNetworkContainer />
                  </div>
                </template>
              </MediaDetailContent>
            </template>

            <template v-if="detailPageType === DetailPageType.NEWSDETAIL">
              <NewsDetailContent v-if="result" :newsArticle="(result as NewsDetailPage)" />
            </template>

            <template
              v-if="
                detailPageType === DetailPageType.AUDIOPODCASTDETAIL ||
                detailPageType === DetailPageType.VIDEOPODCASTDETAIL
              "
            >
              <PodcastDetailContent v-if="result" :podcastDetail="(result as PodcastDetailPage)">
                <template #detailPageActions>
                  <div class="mt-7 flex flex-row gap-2 lg:hidden">
                    <ToggleButton
                      v-if="detailPageID"
                      class="w-max"
                      unchecked-name="Bookmark"
                      checked-name="Bookmarked"
                      unchecked-icon-name="Bookmark"
                      checked-icon-name="Haken"
                      :is-checked="isBookmarked"
                      :loading="bookmarkLoading"
                      v-protected:click="setBookmarkClick"
                    />
                    <SocialNetworkContainer />
                  </div>
                </template>
              </PodcastDetailContent>
            </template>

            <template v-if="detailPageType === DetailPageType.RADIODETAIL">
              <RadioShowDetailContent
                v-if="result"
                :radio-show-detail="(result as RadioShowDetailPage)"
              >
                <template #detailPageActions>
                  <div class="mt-7 flex flex-row gap-2 lg:hidden">
                    <ToggleButton
                      v-if="detailPageID"
                      class="w-max"
                      unchecked-name="Bookmark"
                      checked-name="Bookmarked"
                      unchecked-icon-name="Bookmark"
                      checked-icon-name="Haken"
                      :is-checked="isBookmarked"
                      :loading="bookmarkLoading"
                      v-protected:click="setBookmarkClick"
                    />
                    <SocialNetworkContainer />
                  </div>
                </template>
              </RadioShowDetailContent>
            </template>
          </main>
        </template>

        <Discuss v-if="discussEnabled && result" :articleId="result.id" />

        <div class="px-4 md:px-8 lg:px-6">
          <MiniExplore
            v-if="miniExploreResult"
            :reels="miniExploreResult"
            :loading="miniExploreLoading"
            :is-detail-page="true"
          />
        </div>
      </article>
    </div>
  </div>
</template>
