<script lang="ts" setup>
import { ImageVariants, InlinePlayerImage, MediaContentType } from "@/types/prime";
import { convertImageUrlSet } from "@/utilities/imageUtils";

const props = defineProps<{
  src: ImageVariants | InlinePlayerImage;
  alt?: string;
  mediaContentType?: MediaContentType;
}>();

const srcset = computed(() =>
  "lg" in props.src // type of src is ImageVariants
    ? [props.src.lg, props.src.md, props.src.sm]
    : convertImageUrlSet(props.src, props.mediaContentType),
);

const minWidth = [1200, 768, 0];

const withWebp = (uri: string) => {
  const url = new URL(uri);
  url.searchParams.set("format", "webp");

  const width = url.searchParams.get("width");
  const height = url.searchParams.get("height");
  if (width && height) {
    url.searchParams.set("width", (parseInt(width) * window.devicePixelRatio).toString());
    url.searchParams.set("height", (parseInt(height) * window.devicePixelRatio).toString());
  }

  return url.toString();
};

const sources = computed(() => srcset?.value.filter((s) => !!s).map(withWebp));

const el = ref<HTMLPictureElement | null>(null);
</script>

<template>
  <picture ref="el" class="relative block h-full">
    <source
      v-for="(src, index) in sources"
      :srcset="src"
      :key="index"
      :media="`(min-width:${minWidth[index]}px)`"
    />
    <div class="absolute inset-0 flex h-full items-center justify-center bg-neutral-125 px-2">
      <FallbackLogo class="h-5 lg:max-h-7" />
    </div>
    <img
      v-if="sources?.slice(-1)[0]"
      :src="sources?.slice(-1)[0]"
      :alt="alt || 'Image'"
      class="relative h-full w-full object-cover"
    />
  </picture>
</template>
