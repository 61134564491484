<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";
import { useErrorStore } from "@/store/error";

const errorStore = useErrorStore();

let errorToastTimeout: NodeJS.Timeout;
watch(
  () => errorStore.isShowing,
  () => {
    if (errorStore.isShowing) {
      errorToastTimeout = setTimeout(() => {
        errorStore.hide();
      }, 3000);
    }
  },
);

onUnmounted(() => {
  clearTimeout(errorToastTimeout);
});
</script>

<template>
  <TransitionRoot
    class="fixed inset-x-0 bottom-4 z-50"
    :show="errorStore.isShowing"
    enter="transition transform duration-75"
    enter-from="translate-y-[100px]"
    enter-to="translate-y-full"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div class="flex w-full justify-center bg-transparent shadow-lg">
      <p
        class="mx-4 rounded-md bg-neutral-125 px-4 py-3 text-center text-base font-bold lg:w-max lg:px-52"
      >
        {{ errorStore.message }}
      </p>
    </div>
  </TransitionRoot>
</template>
