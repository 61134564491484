<script setup lang="ts">
import { useGetMyContent, useGetMyContentByIds } from "@/graphql/composables/my-content";
import { MediaType } from "@/graphql/generated/client";
import { useDebounceFn, useStorage } from "@vueuse/core";
import { useUserStore } from "@/store/user";
import { useModalStore } from "@/store/modal";
import { MyContentType } from "@/types/prime";
import { usePlayHistory } from "@/graphql/composables/play-history";

definePage({ meta: { requiresAuth: true, pageName: "my_content" } });

const userStore = useUserStore();
const modalStore = useModalStore();

const {
  result: followingResult,
  loading: followingLoading,
  error: followingError,
  load: loadFollowing,
} = useGetMyContent(MyContentType.FOLLOWING);

const {
  result: bookmarkResult,
  loading: bookmarkLoading,
  error: bookmarkError,
  load: loadBookmark,
} = useGetMyContent(MyContentType.BOOKMARK);
const {
  result: followingByIdResult,
  loading: followingByIdLoading,
  error: followingByIdError,
  loadFollowingContentByIds,
} = useGetMyContentByIds();

const {
  result: bookmarkByIdResult,
  loading: bookmarkByIdLoading,
  error: bookmarkByIdError,
  loadBookmarkContentByIds,
} = useGetMyContentByIds();
const selectedFilter = useStorage<MediaType>("mycontent-filter", MediaType.All, sessionStorage);

const debouncedLoad = useDebounceFn(() => {
  loadFollowing(undefined, { mediaType: selectedFilter.value });
  loadBookmark(undefined, { mediaType: selectedFilter.value });
}, 200);

const handleFilter = async (f: MediaType) => {
  selectedFilter.value = f;
  debouncedLoad();
};

const bookmarkCount = computed(() => bookmarkResult.value?.length ?? 0);
const followingCount = computed(() => followingResult.value?.length ?? 0);

watch(
  [followingResult, bookmarkResult],
  () => {
    loadFollowingContentByIds(followingResult.value, 20);
    loadBookmarkContentByIds(bookmarkResult.value, 20);
  },
  { deep: true, immediate: true },
);

watch(
  selectedFilter,
  () => {
    if (selectedFilter.value) {
      debouncedLoad();
    }
  },
  { deep: true, immediate: true },
);

onMounted(async () => {
  const user = await userStore.loadUser();

  if (!user) {
    modalStore.regwall.isOpen = true;
  }
});

const {
  result: playbackHistoryResult,
  loading: playbackHistoryLoading,
  load: loadPlaybackHistory,
} = usePlayHistory(userStore.userId ?? "");

const bookmarkedEpisodes = computed(() => [
  ...(followingByIdResult.value?.result ?? []),
  ...(bookmarkByIdResult.value?.result ?? []),
]);

watch(bookmarkedEpisodes, () => {
  userStore.userId &&
    loadPlaybackHistory(undefined, {
      userId: userStore.userId,
    });
});
</script>

<template>
  <Error v-if="bookmarkByIdError || followingByIdError" />
  <div v-else class="-mx-4 h-full overflow-auto px-4 pb-48 lg:-mx-8 lg:px-8">
    <h1 class="mb-11 mt-12 font-inter text-4xl font-bold tracking-heading lg:text-5xl">
      My Content
    </h1>
    <FilterBar class="mt-2" :filter="selectedFilter" :on-filter="handleFilter" />
    <SkeletonLoading v-if="bookmarkLoading || followingLoading" />
    <div v-else>
      <section>
        <div class="mt-10 flex items-center justify-between lg:mt-16">
          <h2 class="text-lg font-bold lg:text-xl">Bookmarked shows</h2>
          <router-link to="/my-content/followings" v-if="followingCount > 6">show all</router-link>
        </div>

        <EmptyState
          title="You don’t have any bookmarked shows yet"
          icon-url="/images/icon-empty-bookmarks.png"
          v-if="followingByIdResult?.result.length == 0"
        />
        <div
          class="relative -mx-4 mt-4 flex select-none justify-start gap-4 overflow-x-auto px-4 lg:mx-0"
        >
          <template v-if="followingByIdLoading">
            <div
              v-for="index in 3"
              :key="index"
              class="aspect-square h-36 animate-pulse overflow-hidden rounded-md bg-loading"
            ></div>
          </template>
          <template v-for="result in followingByIdResult?.result.slice(0, 6) ?? []" v-else>
            <router-link :to="result.url!">
              <div class="aspect-square h-36 overflow-hidden rounded-md">
                <Image
                  :src="{
                    lg: result.imageUrl + '?width=208&height=208',
                    md: result.imageUrl + '?width=208&height=208',
                    sm: result.imageUrl + '?width=208&height=208',
                  }"
                  :alt="result.headline"
                  class="w-full"
                />
              </div>
            </router-link>
          </template>
        </div>
      </section>

      <h2 class="mt-10 text-lg font-bold lg:mt-16 lg:text-xl">Bookmarked episodes</h2>

      <EmptyState
        title="You don’t have any bookmarked episodes yet"
        icon-url="/images/icon-empty-following.png"
        v-if="bookmarkByIdResult?.result.length == 0"
      />
      <template v-if="bookmarkByIdLoading || playbackHistoryLoading">
        <EpisodeSkeletonLoading />
      </template>
      <template v-else>
        <BookmarkedEpisode
          v-for="result in bookmarkByIdResult?.result.slice(0, 3) ?? []"
          :key="result.mediaId"
          :episode="result"
          :playback-history="playbackHistoryResult?.find((i) => i.mediaId == result.mediaId)"
        />
        <div v-if="bookmarkCount >= 4" class="mt-4">
          <router-link
            to="/my-content/bookmarks"
            class="'w-full inline-block rounded-[7px] bg-neutral-interaction px-5 py-3 font-inter text-base font-bold transition-colors hover:bg-neutral-300"
          >
            Show All
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>
