import { log, AnalyticsLog, AnalyticsParam } from "@/services/analytics";
import { isLoggedIn } from "@/services/auth";
import { useModalStore } from "@/store/modal";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default async function routerMiddleware(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const dynamicParams: Record<string, string> = {};

  switch (to.meta.pageName) {
    case "detail_page":
      dynamicParams[AnalyticsParam.slug] = to.fullPath;
      break;
    case "microsite":
      dynamicParams[AnalyticsParam.type] = to.params.name as string;
      break;
    case "station_selection":
      dynamicParams[AnalyticsParam.station_name] = to.params.id as string;
      break;
  }

  log(AnalyticsLog.screen_view, {
    [AnalyticsParam.page_name]: to.meta.pageName as string,
    ...dynamicParams,
  });

  const modalStore = useModalStore();

  const isAuthenticated = await isLoggedIn();

  if (to.meta.requiresAuth && !isAuthenticated) {
    modalStore.openRegwall();
    next(false);
  } else {
    return next();
  }
}
