import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_APIKEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AuthDomain,
  projectId: import.meta.env.VITE_APP_FIREBASE_ProjectId,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_StorageBucket,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MessagingSenderId,
  appId: import.meta.env.VITE_APP_FIREBASE_AppId,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MeasurementId,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig, "webapp");
export const analytics = getAnalytics(firebaseApp);

export const log = (eventName: string, params: Record<string, string>) =>
  logEvent(analytics, eventName, params);

export const logSocialShare = (params: Record<string, string>) =>
  log(AnalyticsLog.share_content, { ...params });

export enum AnalyticsLog {
  screen_view = "screen_view",
  share_content = "share_content",
}

export enum AnalyticsParam {
  page_name = "page_name",
  slug = "slug",
  station_name = "station_name",
  type = "type",
}
