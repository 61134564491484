import { createApp } from "vue";

import { createRouter, createWebHistory } from "vue-router/auto";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia } from "pinia";
import { createHead } from "@vueuse/head";

import { useModalStore } from "@/store/modal/index";
import apolloClient from "@/graphql/apollo-client";
import { analytics } from "@/services/analytics";
import { isLoggedIn } from "@/services/auth";
import routerMiddleware from "@/middleware/router";
import { initSentry } from "@/plugins/sentry";

import App from "./App.vue";
import "@/services/ad-manager";

import "./styles/index.css";

const isAppsWebview = window.location.href.includes("hideMobileNavBar");

if (!isAppsWebview) {
  import("smartbanner.js");
  import("smartbanner.js/dist/smartbanner.min.css");
}

const protectedDirective = {
  mounted(el: HTMLElement, binding: any) {
    const modalStore = useModalStore();

    el.addEventListener(binding.arg, async () => {
      if (!(await isLoggedIn())) {
        return modalStore.openRegwall();
      }

      binding.value();
    });
  },
  unmounted(el: HTMLElement, binding: any) {
    el.removeEventListener(binding.arg, () => {});
  },
};

const router = createRouter({
  history: createWebHistory(),
});

router.beforeEach(routerMiddleware);

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

initSentry(app, router);

app.config.globalProperties.$analytics = analytics;

const head = createHead();

app.use(createPinia());
app.use(router);

app.use(head);

app.directive("protected", protectedDirective);

app.mount("#app");
