<script setup lang="ts">
import * as authService from "@/services/auth";
import { useUserStore } from "@/store/user";

definePage({ meta: { hideMobileNavigationBar: true, pageName: "settings" } });

const userStore = useUserStore();

const onClickLogin = () => {
  authService.login();
};

const onClickChangePassword = () => {
  authService.changePassword();
};

const onClickLogout = () => {
  authService.logout();
};

const onClickViewProfile = () => {
  authService.deleteAccount();
};

const showPrivacyManager = () => {
  if (localStorage.getItem("_sp_message_type") === "gdpr")
    window._sp_.gdpr.loadPrivacyManagerModal(
      +import.meta.env.VITE_SOURCEPOINT_GDPR_PRIVACY_MANAGER_ID,
    );
  else {
    window._sp_.usnat.loadPrivacyManagerModal(
      +import.meta.env.VITE_SOURCEPOINT_USNAT_PRIVACY_MANAGER_ID,
    );
  }
};

const user = computed(() => userStore.user);
</script>

<template>
  <div class="flex w-full flex-col justify-center lg:mt-14">
    <div class="sticky top-0 grid grid-cols-3 bg-prime-black py-2 lg:hidden">
      <router-link to="/">
        <IconBack />
      </router-link>
      <p class="cursor-pointer justify-self-center text-base font-bold">Settings</p>
    </div>
    <h1 class="hidden text-3xl font-extrabold lg:flex">Settings</h1>
    <h6 class="mt-10 text-lg font-bold lg:mt-16 lg:text-xl">Account</h6>
    <div class="mt-4" v-if="!user">
      <button
        class="w-full rounded bg-neutral-125 py-3 font-inter font-bold lg:w-max lg:px-4"
        @click="onClickLogin"
      >
        Login
      </button>
    </div>
    <div v-else>
      <div class="flex flex-col gap-5">
        <p class="cursor-pointer text-base font-normal text-neutral-500">
          {{ user.profile.email }}
        </p>
        <!-- <p class="cursor-pointer text-base font-normal text-success lg:text-lg">
          Active Plus-Account
        </p> -->
        <hr class="h-[0.5px] border-0 bg-neutral-500 opacity-30" />
        <p class="cursor-pointer text-base font-normal lg:text-lg" @click="onClickChangePassword">
          Change Password
        </p>
        <hr class="h-[0.5px] border-0 bg-neutral-500 opacity-30" />
        <p
          class="cursor-pointer text-center text-base font-normal lg:text-left lg:text-lg"
          @click="onClickLogout"
        >
          Logout
        </p>

        <!-- <p
          class="mt-2 cursor-pointer text-center text-base font-bold lg:mt-0 lg:text-left lg:text-lg"
        >
          Restore Purchases
        </p> -->
        <!-- <hr class="hidden h-[0.5px] border-0 bg-neutral-500 opacity-30 lg:flex" /> -->
      </div>
    </div>
    <div class="mb-10 mt-16 flex flex-col gap-9">
      <h6 class="text-lg font-bold lg:text-xl">Other</h6>
      <div class="flex flex-col gap-5">
        <router-link to="/sites/primediaplus-privacy" class="cursor-pointer text-lg font-normal">
          Privacy Notice
        </router-link>
        <hr class="h-[0.5px] border-0 bg-neutral-500 opacity-30" />
        <router-link
          to="/sites/primediaplus-terms-and-conditions"
          class="cursor-pointer text-lg font-normal"
        >
          Terms and Conditions
        </router-link>
        <hr class="h-[0.5px] border-0 bg-neutral-500 opacity-30" />
        <router-link to="/sites/primediaplus-paia" class="cursor-pointer text-lg font-normal">
          PAIA
        </router-link>
        <hr class="h-[0.5px] border-0 bg-neutral-500 opacity-30" />
        <span
          @click="showPrivacyManager"
          id="sp-sdk-btn"
          class="ot-sdk-show-settings cursor-pointer font-normal"
        >
          Cookies Settings
        </span>
      </div>
    </div>
    <template v-if="user">
      <p
        class="mt-11 cursor-pointer text-center text-base font-normal text-desctructive lg:text-left lg:text-lg"
        @click="onClickViewProfile"
      >
        Delete Account
      </p>
      <hr class="mt-5 hidden h-[0.5px] border-0 bg-neutral-500 opacity-30 lg:block" />
    </template>
  </div>
</template>

<style scoped>
#sp-sdk-btn {
  color: inherit !important;
  border: unset !important;
  background: unset !important;
  padding: unset !important;
  font: inherit !important;
  font-size: 1.125rem !important;
}
</style>
